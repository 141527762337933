import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Header from "../../components/Header";
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";

const KeywordHistoric = () => {
  const [columnAdsKeyword, setColumnAdsKeyword] = useState([]);
  const [rowDataKeyword, setRowDataKeyword] = useState([]);
  const gridRef = useRef(null);
  const tableContainerRef = useRef();
  const [availableHeight, setAvailableHeight] = useState(0);

  const extractDates = useCallback((data) => {
    if (!Array.isArray(data) || data.length === 0) return [];  
    const row = data[0];  
    const allKeys = typeof row === 'object' && row !== null ? Object.keys(row) : [];  
    const dateKeys = allKeys.filter((key) => key.match(/^\d{4}-\d{2}-\d{2}/));
    const dates = [...new Set(dateKeys.map(key => key.split(' ')[0]))];  
    return dates;
  }, []);

  const generateAdsColumnsDef = useCallback((dates) => {
    const sortedDates = dates.sort((a, b) => new Date(b) - new Date(a));
    
    const staticColumns = [
      {
        headerName: 'Search Term',
        children: [
          { headerName: "Country", field: "country", sortable: true, filter: true, width: 120, pinned: 'left' },
          { headerName: "Line", field: "line", sortable: true, filter: true, width: 110, pinned: 'left' },
          { headerName: "Search Term", field: "search_term", sortable: true, filter: true, width: 220, pinned: 'left' }
        ]
      }
    ];
  
    const createDeltaColumn = (metric, currentDate, previousDate, deltaType) => ({
      headerName: metric,
      field: `${deltaType}_${metric.toLowerCase()}_delta`,
      sortable: true,
      filter: 'agNumberColumnFilter',
      width: 120,
      cellStyle: params => ({
        textAlign: 'right',
        color: metric === 'ACoS%' 
          ? (params.value < 0 ? 'green' : params.value > 0 ? 'red' : 'black')
          : (params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black')
      }),
      valueGetter: params => {
        if (!params.data) return null;
        const currentValue = params.data[`${currentDate} ${metric.toLowerCase()}`];
        const previousValue = params.data[`${previousDate} ${metric.toLowerCase()}`];
        
        if (metric === 'ACoS%') {
          const current = Number(currentValue);
          const previous = Number(previousValue);
          return isNaN(current) || isNaN(previous) ? 0 : current - previous;
        } else {
          return (currentValue || 0) - (previousValue || 0);
        }
      },
      valueFormatter: params => {
        if (params.value == null) return '';
        if (metric === 'ACoS%') {
          const formattedValue = Math.abs(params.value).toFixed(1);
          return params.value > 0 ? `+${formattedValue}%` : params.value < 0 ? `-${formattedValue}%` : `${formattedValue}%`;
        } else {
          const formattedValue = Math.abs(params.value).toLocaleString('en-US');
          return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
        }
      },
      aggFunc: 'sum',
      headerTooltip: `Compares ${currentDate} to ${previousDate}`
    });
    
    const momColumns = {
      headerName: 'MoM',
      children: [
        createDeltaColumn('Clicks', sortedDates[0], sortedDates[1], 'mom'),
        createDeltaColumn('Orders', sortedDates[0], sortedDates[1], 'mom'),
      ]
    };
    
    const yoyColumns = {
      headerName: 'YoY',
      children: [
        createDeltaColumn('Clicks', sortedDates[0], sortedDates[12] || sortedDates[sortedDates.length - 1], 'yoy'),
        createDeltaColumn('Orders', sortedDates[0], sortedDates[12] || sortedDates[sortedDates.length - 1], 'yoy'),
      ]
    };
  
    const dateColumns = sortedDates.map(date => ({
      headerName: date,
      children: [
        { headerName: "Clicks", field: `${date} clicks`, sortable: true, filter: 'agNumberColumnFilter', cellStyle: { textAlign: 'right' }, width: 110 },
        { headerName: "Orders", field: `${date} orders`, sortable: true, filter: 'agNumberColumnFilter', cellStyle: { textAlign: 'right' }, width: 110 },
        { headerName: "ACoS %", field: `${date} acos`, sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value ? (params.value * 100).toFixed(1) + '%' : '' }
      ]
    }));
  
    return [...staticColumns, momColumns, yoyColumns, ...dateColumns];
  }, []);

  const handleAdsKeyword = useCallback(async () => {
    try {
      console.log('Initiating Ads Keyword Data');
      const response = await fetch('/api/ads/get_adskeyword_data');
      if (!response.ok) {
        const err = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${err.error || 'Unknown error'}`);
      }
      const data = await response.json();
  
      if (Array.isArray(data) && data.length > 0) {
        const dates = extractDates(data);
        const columns = generateAdsColumnsDef(dates);
        setColumnAdsKeyword(columns);
        setRowDataKeyword(data);
      } else {
        console.error("Data is empty or not in expected format", data);
      }
    } catch (err) {
      console.error('Error fetching data:', err.message);
    }
  }, [extractDates, generateAdsColumnsDef]);

  useEffect(() => {
    handleAdsKeyword();
  }, [handleAdsKeyword]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  // Calculate available height for the grid
  const calculateHeight = useCallback(() => {
    if (tableContainerRef.current) {
      const offsetHeight = tableContainerRef.current.offsetTop + 50;
      setAvailableHeight(window.innerHeight - offsetHeight);
    }
  }, []);

  useEffect(() => {
    calculateHeight();
    window.addEventListener('resize', calculateHeight);
    return () => window.removeEventListener('resize', calculateHeight);
  }, [calculateHeight]);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Keyword Historic</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV} 
            style={{ marginRight: '10px' }}
          >
            Export CSV
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </Box>
      </Box>
      <Box ref={tableContainerRef} style={{ flexGrow: 1, overflow: 'hidden' }}>
        <div className="ag-theme-alpine" style={{ height: availableHeight ? `${availableHeight}px` : '800px', width: '100%', overflowY: 'auto' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnAdsKeyword}
            rowData={rowDataKeyword}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
          />
        </div>
      </Box>
    </div>
  );
}

export default KeywordHistoric;