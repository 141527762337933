import React from 'react';

export const columns = [
    { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
    { headerName: "Line", field: "line", sortable: true, filter: true, width: 150 },
    { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 150 },
    { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 200 },
    { headerName: "Risk Flag", field: "risk_flag", sortable: true, filter: true, width: 120 },
    { headerName: "DOC", field: "doc", sortable: true, filter: true, width: 120 },
    { headerName: "Missed Days", field: "missed_days", sortable: true, filter: true, width: 120 },
    { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
    { headerName: "CC Price", field: "price_cc", sortable: true, filter: true, width: 120 },
    { headerName: "Dynamic Price", field: "new_price", sortable: true, filter: 'agNumberColumnFilter', width: 120, editable: true  },
    { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true  }, 
];


export const columns_sku = [
    { headerName: "Country", field: "country", sortable: true, filter: true, width: 120 },
        { headerName: "Line", field: "line", sortable: true, filter: true, width: 150 },
        { headerName: "SKU", field: "sku", sortable: true, filter: true, width: 150 },
        { headerName: "Feed Product Type", field: "feed_product_type", sortable: true, filter: true, width: 200 },
        { headerName: "Live Price", field: "live_price", sortable: true, filter: true, width: 120 },
        { headerName: "New Price", field: "new_price", sortable: true, filter: true, width: 120, editable: true  },
        { headerName: "Min Price", field: "min_price", sortable: true, filter: true, width: 120, editable: true  },        
];

export const BQ_Repricing_Log_Columns = [
    { field: "time_id", headerName: "Time ID", flex: 1,sortable: true, filter: true },
    { field: "country", headerName: "Country", flex: 1,sortable: true, filter: true, maxWidth : 120 },
    { field: "feedId", headerName: "Feed ID", flex: 1,sortable: true, filter: true },
    // { field: "operationType", headerName: "Operation Type", flex: 1,sortable: true, filter: true },
    { field: "message_id", headerName: "Message ID", flex: 1,sortable: true, filter: true, maxWidth : 100 },
    { field: "sku", headerName: "SKU", flex: 1,sortable: true, filter: true },
    { field: "doc", headerName: "DOC", flex: 1,sortable: true, filter: 'agNumberColumnFilter', maxWidth : 120,cellStyle: { textAlign: 'center'} },
    { field: "missed_days", headerName: "Missed Days", flex: 1,sortable: true, filter: 'agNumberColumnFilter', maxWidth : 120,cellStyle: { textAlign: 'center'} },
    { field: "risk_flag", headerName: "Risk Flag", flex: 1,sortable: true, filter: true, maxWidth : 140},
    { field: "live_price", headerName: "Live Price", flex: 1,sortable: true, maxWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'} },
    { field: "reqd_price", headerName: "Reqd Price", flex: 1,sortable: true, maxWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'} },
    { field: "dynamic_price", headerName: "Dynamic Price", flex: 1, sortable: true, maxWidth : 140, filter: 'agNumberColumnFilter',cellStyle: { textAlign: 'center'},
    cellClass: params => {
        if (!params.data || params.data.dynamic_price === undefined || params.data.reqd_price === undefined) { return ''; }
        if (params.data.dynamic_price > params.data.reqd_price) { return 'ag-cell-green'; }
        if (params.data.dynamic_price < params.data.reqd_price) { return 'ag-cell-red'; } return '';},
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '', 
      aggFunc: 'sum' 
    },
    { field: "rule", headerName: "Rule", flex: 1, sortable: true, filter: true, minWidth: 320},
  ];