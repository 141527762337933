import React from 'react';
import { getRatioValue } from '../../components/global/getRatioValue'; 
import AlertPillsCellRenderer from '../../components/global/AlertPillsCellRenderer.jsx';
import ScratchpadNotes from '../../components/global/ScratchpadNotes';

export const columns = [
    {
      headerName: 'Line Info',
      children: [
        { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
        { headerName: "Line", field: "line", sortable: true, filter: true, width: 110, pinned: 'left', enableRowGroup: true },
        { headerName: "Link", field: "link", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true, cellStyle: { textAlign: 'center' },
          cellRenderer: params => { if (!params.value) { return null; } const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
          return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'}  }, '🔗');  } },
        { headerName: "Region", field: "region", sortable: true, filter: true, width: 125, enableRowGroup: true },
        { headerName: "Channel", field: "sales_channel", sortable: true, filter: true, width: 125, enableRowGroup: true, columnGroupShow: 'open'  },
        { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 100, enableRowGroup: true },
        { headerName: "Group", field: "group", sortable: true, filter: true, width: 110, columnGroupShow: 'open', enableRowGroup: true  },
        { headerName: "Segment", field: "segment", sortable: true, filter: true, width: 110, columnGroupShow: 'open', enableRowGroup: true },
        { headerName: "Launch Date", field: "launch_date", sortable: true, filter: 'agDateColumnFilter', width: 110, enableRowGroup: true, cellStyle: { textAlign: 'right' }, columnGroupShow: 'open' },
        { headerName: "Currency", field: "currency", sortable: true, filter: true, width: 100, columnGroupShow: 'open' },
        { headerName: "Ungrouped MA", field: "ungrouped_ma", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Ungrouped %", field: "ungrouped_percentage", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          cellClass: params => params.value > 40 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => { if (params.value !== null && params.value !== undefined) { return `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})} %`; } return ''; },
          valueParser: params => { return Number(params.newValue); },  filterValueGetter: params => { return params.data.ungrouped_percentage; }, enableRowGroup: true  },
        { headerName: "# Parent", field: "parent_asin_count", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Parent ASINs", field: "asin_in_each_parent", sortable: true, filter: true, width: 200, columnGroupShow: 'open' },
        { headerName: "INSMA %", field: "insma_perc", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' },  
          cellClass: params => params.value < 60 ? 'ag-cell-red' : '',
          valueFormatter: params => { if (params.value !== null && params.value !== undefined) { return `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})} %`; } return ''; },
          valueParser: params => { return Number(params.newValue); },  filterValueGetter: params => { return params.data.insma_perc; }, enableRowGroup: true  },  
        { headerName: "Cat A OOS",  field: "Cat_A_OOS",   sortable: true,  filter: 'agSetColumnFilter', width: 120, filterParams: {  values: ['Blanks', 'Yes', 'No']  },  cellStyle: { textAlign: 'center' }, 
          cellClass: params => params.value === "Yes" ? 'ag-cell-red' : '',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',  enableRowGroup: true
        },
        {
          headerName: "Notes",
          field: "notes",
          width: 80,
          sortable: false,
          filter: false,
          cellRenderer: params => {
            if (!params.data) return null;
            
            return (
              <ScratchpadNotes
                line={params.data.line}
                country={params.data.country_code}
              />
            );
          }
        },
      ]
    },
    {
      headerName: 'Alerts',
      children: [
        {
          headerName: "Alerts",
          field: "alerts",
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
            textCustomComparator: function (filter, value, filterText) {
              if (typeof value === 'string') {
                return value.split(',').some(alert => alert.trim() === filterText);
              }
              if (Array.isArray(value)) {
                return value.some(alert => alert === filterText);
              }
              return false;
            }
          },
          width: 180,
          cellRenderer: AlertPillsCellRenderer,
          valueGetter: params => params.data ? params.data.alerts : null,
          filterValueGetter: params => {
            if (!params.data || !params.data.alerts || 
                (Array.isArray(params.data.alerts) && params.data.alerts.length === 0)) {
              return null;
            }
            if (Array.isArray(params.data.alerts)) {
              return params.data.alerts.map(alert => 
                typeof alert === 'object' ? alert.message : alert
              ).join(', ');
            }
            return typeof params.data.alerts === 'object' ? params.data.alerts.message : params.data.alerts;
          },
          getQuickFilterText: params => {
            if (params.data && params.data.alerts) {
              if (Array.isArray(params.data.alerts)) {
                return params.data.alerts.map(alert => 
                  typeof alert === 'object' ? alert.message : alert
                ).join(' ');
              }
              return typeof params.data.alerts === 'object' ? params.data.alerts.message : params.data.alerts;
            }
            return '';
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const alertsA = nodeA && nodeA.data && nodeA.data.alerts ? 
                            (Array.isArray(nodeA.data.alerts) ? nodeA.data.alerts : [nodeA.data.alerts]) : [];
            const alertsB = nodeB && nodeB.data && nodeB.data.alerts ? 
                            (Array.isArray(nodeB.data.alerts) ? nodeB.data.alerts : [nodeB.data.alerts]) : [];
            return alertsA.length - alertsB.length;
          },
          headerTooltip: `YoY Decrease: Sales decreased by more than 30% compared to last year.
YoY Increase: Sales increased by more than 30% compared to last year.
MoM Decrease: Sales decreased by more than 10% compared to last month.
MoM Increase: Sales increased by more than 10% compared to last month.
High ACOS: Advertising Cost of Sales is above 50%.
High TACOS: Total Advertising Cost of Sales is 25% or higher.
Low TACOS: Total Advertising Cost of Sales is 5% or lower.
Very High TACOS: Total Advertising Cost of Sales is 35% or higher.
High DOC: Days of Cover > 120 days with FBA stock > 100.
Stock/Grouping Issues: INSMA < 60% or Cat A OOS or Ungrouped > 30%.
Loss Making: Negative Net Profit Margin in the last 2 months.
Profit Making: Positive Net Profit Margin in the last 2 months.
High ADR: Advertising Detail Rate > 60% in the last 7 days.
Low ADR: Advertising Detail Rate < 20% in the last 7 days.
Low CVR: Conversion Rate < 8%.
No Ad Spend: No ad spend with FBA stock > 0 in the last 7 days.
New Launch : If Launch Date in last 12 Months `
        }
      ]
    },
    {
      headerName: 'Sales Data',
      children: [
        { headerName: "MA", field: "ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum' },
        { headerName: "L7 MA", field: "l7_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum'},
        { headerName: "P7 MA", field: "P7_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: 'Delta', field: 'deltaw', sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          valueGetter: params => { if (!params.data) return null; return (params.data.l7_ma || 0) - (params.data.P7_ma || 0); },
          valueFormatter: params => { if (params.value == null) return ''; const formattedValue = Math.abs(params.value).toLocaleString('en-US', {maximumFractionDigits: 1});
          return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
          }, aggFunc: 'sum', headerTooltip: 'L7 MA - P7 MA', columnGroupShow: 'open'},
        { headerName: "L30 MA", field: "l30_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L90 MA", field: "l90_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { 
          headerName: "L7 Units", 
          field: "l7_sum",
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            return Array.from({ length: 7 }, (_, i) => params.data[`_${i + 1}`] || 0).reduce((sum, val) => sum + val, 0);
          },
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '',
          aggFunc: 'sum'
        },
        { 
          headerName: "P7 Units", 
          field: "p7_sum",
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            return Array.from({ length: 7 }, (_, i) => params.data[`_${i + 8}`] || 0).reduce((sum, val) => sum + val, 0);
          },
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '',
          aggFunc: 'sum'
        },
        { 
          headerName: "Delta", 
          field: "l7_p7_delta",
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 100,
          cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            const l7 = Array.from({ length: 7 }, (_, i) => params.data[`_${i + 1}`] || 0).reduce((sum, val) => sum + val, 0);
            const p7 = Array.from({ length: 7 }, (_, i) => params.data[`_${i + 8}`] || 0).reduce((sum, val) => sum + val, 0);
            return l7 - p7;
          },
          valueFormatter: params => {
            if (params.value == null) return '';
            const formattedValue = Math.abs(params.value).toLocaleString('en-US');
            return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
          },
          aggFunc: 'sum'
        },
        { headerName: "Max MA", field: "max_ma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'Inventory',
      children: [
        { headerName: "FBA Stock", field: "fba_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "MFN Stock", field: "mfn_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "FC Stock", field: "fc_stock", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "DOC", field: "doc", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return Math.round(params.value).toLocaleString('en-US'); } return ''; },
          valueGetter: params => { if (!params.data) return null; const ma = params.data.ma; const fba_stock = params.data.fba_stock; return (ma && fba_stock) ? (fba_stock / ma) : null; } },
        { headerName: "INSMA", field: "insma", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toFixed(1) : '', aggFunc: 'sum', columnGroupShow: 'open'  },
        { headerName: "Inv at Risk", field: "inv_at_risk", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open'},
        { headerName: "Inv Short LTSF", field: "inv_short_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Inv Long LTSF", field: "inv_long_ltsf", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'SP Ads (Last 7 Days)',
      children: [
        { headerName: "L7 Impressions", field: "l7_impressions", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_impressions && params.data.p7_impressions) {
                if (params.data.l7_impressions / params.data.p7_impressions >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_impressions / params.data.p7_impressions <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 Impressions", field: "p7_impressions", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L7 Clicks", field: "l7_clicks", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_clicks && params.data.p7_clicks) {
                if (params.data.l7_clicks / params.data.p7_clicks >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_clicks / params.data.p7_clicks <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 Clicks", field: "p7_clicks", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L7 Spend", field: "l7_cost", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', aggFunc: 'sum',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_cost && params.data.p7_cost) {
                if (params.data.l7_cost / params.data.p7_cost >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_cost / params.data.p7_cost <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 Spend", field: "p7_cost", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L7 Sales", field: "l7_sales_ads", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_sales_ads && params.data.p7_sales_ads) {
                if (params.data.l7_sales_ads / params.data.p7_sales_ads >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_sales_ads / params.data.p7_sales_ads <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 Sales", field: "p7_sales_ads", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L7 Orders", field: "l7_orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_orders && params.data.p7_orders) {
                if (params.data.l7_orders / params.data.p7_orders >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_orders / params.data.p7_orders <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 Orders", field: "p7_orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "L7 ACoS", field: "l7_acos", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_acos && params.data.p7_acos) {
                if (params.data.l7_acos / params.data.p7_acos >= 1.2) { return 'ag-cell-red'; } 
                else if (params.data.l7_acos / params.data.p7_acos <= 0.8) { return 'ag-cell-green'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 ACoS", field: "p7_acos", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '', columnGroupShow: 'open' },
        { headerName: "L7 TACoS", field: "l7_tacos", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_tacos && params.data.p7_tacos) {
                if (params.data.l7_tacos / params.data.p7_tacos >= 1.2) { return 'ag-cell-red'; } 
                else if (params.data.l7_tacos / params.data.p7_tacos <= 0.8) { return 'ag-cell-green'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 TACoS", field: "p7_tacos", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '', columnGroupShow: 'open' },
        { headerName: "L7 TACoS Contri.", field: "l7_tacos_contribution", sortable: true, filter: 'agNumberColumnFilter', width: 150, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${(params.value).toFixed(1)} %` : '',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_tacos_contribution && params.data.p7_tacos_contribution) {
                if (params.data.l7_tacos_contribution > params.data.p7_tacos_contribution) { return 'ag-cell-red'; } 
                else if (params.data.l7_tacos_contribution < params.data.p7_tacos_contribution) { return 'ag-cell-green'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 TACoS Contri.", field: "p7_tacos_contribution", sortable: true, filter: 'agNumberColumnFilter', width: 150, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${(params.value).toFixed(1)} %` : '', columnGroupShow: 'open'
        },
        { headerName: "L7 CTR", field: "l7_ctr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${params.value.toFixed(1)} %` : '', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_ctr && params.data.p7_ctr) {
                if (params.data.l7_ctr / params.data.p7_ctr >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_ctr / params.data.p7_ctr <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 CTR", field: "p7_ctr", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${params.value.toFixed(1)} %` : '', columnGroupShow: 'open' },
        { 
          headerName: "Target CVR", 
          field: "target_cvr", 
          sortable: true, 
          filter: 'agNumberColumnFilter', 
          width: 110, 
          cellStyle: { textAlign: 'right' },
          valueGetter: params => {
            if (!params.data) return null;
            const l7Cvr = params.data.l7_cvr;
            const l7Acos = params.data.l7_acos;
            
            if (l7Cvr != null && l7Acos != null) {
              return (l7Cvr * l7Acos) / (0.3 * 100);
            }
            return null;
          },
          valueFormatter: params => params.value != null ? `${params.value.toFixed(0)} %` : '',
        },
        { headerName: "L7 CVR", field: "l7_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${params.value.toFixed(1)} %` : '', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_cvr && params.data.p7_cvr) {
                if (params.data.l7_cvr / params.data.p7_cvr >= 1.2) { return 'ag-cell-green'; } 
                else if (params.data.l7_cvr / params.data.p7_cvr <= 0.8) { return 'ag-cell-red'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 CVR", field: "p7_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${params.value.toFixed(1)} %` : '', columnGroupShow: 'open' },
        { headerName: "L7 ADR", field: "l7_adr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '', columnGroupShow: 'open',
          cellClass: params => {
            if (params.data) {
              if (params.data.l7_adr && params.data.p7_adr) {
                if (params.data.l7_adr / params.data.p7_adr >= 1.2) { return 'ag-cell-red'; } 
                else if (params.data.l7_adr / params.data.p7_adr <= 0.8) { return 'ag-cell-green'; }
              }
            }
            return '';
          },
        },
        { headerName: "P7 ADR", field: "p7_adr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value != null ? `${Math.round(params.value)} %` : '', columnGroupShow: 'open' },
      ]
    },
    {
      headerName: 'Business Reports',
      children: [
        { headerName: "Page Views", field: "total_page_views", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open'  },
        { headerName: "Sessions", field: "total_sessions", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Orders", field: "total_orders", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open'  },
        { headerName: "Units", field: "total_units", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Sales", field: "total_sales", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', aggFunc: 'sum', columnGroupShow: 'open'  },
        { headerName: "CVR", field: "recalculated_cvr", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' },
          valueFormatter: params => { if (params.value != null && !isNaN(params.value)) { return `${(params.value * 100).toFixed(1)} %`; } return ''; },
          valueGetter: params => { if (!params.data) return null; const total_orders = params.data.total_orders; const total_sessions = params.data.total_sessions; return (total_orders && total_sessions) ? total_orders / total_sessions : null; } },
      ]
    },
    {
      headerName: 'Last 30 Days Units Sold',
      children: [
        // Last 30 Days Units Sold - Field Names are _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30
        ...Array.from({ length: 30 }, (_, i) => {
          const date = new Date();
          date.setDate(date.getDate() - i - 2); // Subtract i+2 to start from yesterday
          const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
          return { 
            headerName: formattedDate, 
            field: `_${i + 1}`, 
            sortable: true,
            filter: 'agNumberColumnFilter',
            width: 100,
            cellStyle: { textAlign: 'right' },
            columnGroupShow: i < 7 ? undefined : 'open',
            aggFunc: 'sum',
            valueGetter: params => params.data ? params.data[`_${i + 1}`] : undefined,
            valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
          };
        })
      ]
    },
    {
      headerName: 'Last 24 Months Units Sold',
      children: [
      { headerName: "MTD Sold", field: "mtd_sold", sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: { textAlign: 'right' },   
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
      { 
        headerName: "Projected", 
        field: "projected_sales", 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        width: 110, 
        cellStyle: { textAlign: 'right' },
        valueGetter: params => {
          if (!params.data) return null;
          const mtdSold = params.data.mtd_sold;
          if (mtdSold == null) return null;

          // Get the date from 3 days ago
          const today = new Date();
          const threeDaysAgo = new Date(today);
          threeDaysAgo.setDate(today.getDate() - 2);
          const lastAvailableDay = threeDaysAgo.getDate();

          // Calculate projection
          const currentMonth = today.getMonth();
          const daysInMonth = new Date(today.getFullYear(), currentMonth + 1, 0).getDate();
          const projectedSales = Math.round((mtdSold * daysInMonth) / lastAvailableDay);
          return projectedSales;
        },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        aggFunc: 'sum',
        headerTooltip: 'Projected sales for the entire month based on data from 3 days ago'
      },
      { 
        headerName: 'YoY Delta', 
        field: 'yoy_delta', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        width: 120, 
        cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => { 
          if (params.value == null) return ''; 
          const formattedValue = Math.abs(params.value).toLocaleString('en-US');
          return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
        }, 
        aggFunc: 'sum', 
        headerTooltip: 'Compares Last Month vs 12 Months Prior', 
      },
      { 
        headerName: 'MoM Delta', 
        field: 'mom_delta', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        width: 120, 
        cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
        cellStyle: { textAlign: 'right' },
        valueFormatter: params => { 
          if (params.value == null) return ''; 
          const formattedValue = Math.abs(params.value).toLocaleString('en-US');
          return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
        }, 
        aggFunc: 'sum', 
        headerTooltip: 'Compares Last Month to the one before that', 
      },
      // Last 24 Months Units Sold - Field Names are _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24
      ...Array.from({ length: 24 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i - 1);
        const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' });
        return { 
          headerName: formattedDate, 
          field: `_l${i + 1}`,
          sortable: true, 
          filter: 'agNumberColumnFilter',
          width: 90,
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 6 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : ''
        };
      }),
    ]},
    {
      headerName: 'Last 7 Days Net Profit',
      children: [
        { 
          headerName: "MTD Profit", field: "npm_month_1", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          cellClass: params => 
            params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => {
            if (params.value == null) return '';
            const formattedValue = Math.abs(params.value).toLocaleString('en-US', {maximumFractionDigits: 0});
            return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
          },
          aggFunc: 'sum'
        },
        { 
          headerName: "L7 Profit", field: "l7_profit", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          cellClass: params => 
            params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => {
            if (params.value == null) return '';
            const formattedValue = Math.abs(params.value).toLocaleString('en-US', {maximumFractionDigits: 0});
            return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
          },
          aggFunc: 'sum'
        },
        // Last 7 Days Profit - Field Names are l1, l2, l3, l4, l5, l6, l7
        ...Array.from({ length: 7 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i - 1);
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        return { 
          headerName: formattedDate, 
          field: `l${i + 1}`, 
          sortable: true, filter: 'agNumberColumnFilter', width: 100,
          cellStyle: { textAlign: 'right' },
          aggFunc: 'sum',
          valueGetter: params => params.data ? params.data[`l${i + 1}`] : undefined,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
        };
      }),
    ]},
    {
      headerName: 'Last 6 Month Profit',
      children: [
        { 
          headerName: 'MoM Delta', field: 'mom_delta_profit',  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, 
          cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '', cellStyle: { textAlign: 'right' },
          valueGetter: params => {const month2 = params.data.npm_month_2 || 0;const month3 = params.data.npm_month_3 || 0; return month2 - month3; },
          valueFormatter: params => {  if (params.value == null) return '';  const formattedValue = Math.abs(params.value).toLocaleString('en-US');
            return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue; },  aggFunc: 'sum', 
          headerTooltip: 'Compares Previous Month to the one before that', 
        },
        // Last 6 Months Profit - Field Names are npm_month_2, npm_month_3, npm_month_4, npm_month_5, npm_month_6, npm_month_7
        ...Array.from({ length: 6 }, (_, i) => {
        const date = new Date();
        date.setMonth(date.getMonth() - i - 1);
        const formattedDate = date.toLocaleString('default', { month: 'short', year: '2-digit' });
        return { 
          headerName: formattedDate, 
          field: `npm_month_${i + 2}`,
          sortable: true, filter: 'agNumberColumnFilter',
          width: 100, 
          cellClass: params => params.value > 0 ? 'ag-cell-green' : params.value < 0 ? 'ag-cell-red' : '',
          cellStyle: { textAlign: 'right' },
          columnGroupShow: i < 4 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
        };
      })],
    },
    {
      headerName: 'SB Report (Last Month)',
      children: [
        { headerName: "Units Sold", field: "units_sold_sb", sortable: true, filter: 'agNumberColumnFilter', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Sales", field: "sales_sb", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Returns", field: "returns_sb", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum', columnGroupShow: 'open' },
        { headerName: "Net Profit", field: "net_profit_sb", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', aggFunc: 'sum' },
        { headerName: "Profit %", field: "profit_perc", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', aggFunc: 'sum' },      
        { 
          headerName: "Returns %", 
          field: "return_perc", 
          sortable: true, 
          filter: 'agNumberColumnFilter', 
          width: 110, 
          cellClass: params => params.value >= 20 ? 'ag-cell-red' : '', 
          cellStyle: { textAlign: 'right' }, 
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', 
          aggFunc: 'sum', 
          columnGroupShow: 'open'  
        },
        { 
          headerName: "COGS %", field: "cogs_perc", sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          cellClass: params => {
            if (!params.data) return '';
            const value = params.value;
            const country = params.data.country_code;
            if (country === 'IN' && value <= -35) return 'ag-cell-red';
            if (country !== 'IN' && value <= -25) return 'ag-cell-red';
            return '';
          },
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', 
          aggFunc: 'sum', columnGroupShow: 'open' 
        },
        { 
          headerName: "Fulfillment Fee %", field: "fba_fulfillmentfee_perc", sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          cellClass: params => {
            if (!params.data) return '';
            const value = params.value;
            const country = params.data.country_code;
            if (country === 'IN' && value <= -15) return 'ag-cell-red';
            if (country === 'UK' && value <= -25) return 'ag-cell-red';
            if (['FR', 'DE', 'IT', 'ES', 'JP'].includes(country) && value <= -30) return 'ag-cell-red';
            if (country === 'US' && value <= -35) return 'ag-cell-red';
            if (country === 'CA' && value <= -40) return 'ag-cell-red';
            return '';
          },
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', 
          aggFunc: 'sum', columnGroupShow: 'open'
        },
        {
          headerName: "Storage Fee %", field: "fba_storagefees_perc", sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          cellClass: params => params.value <= -5 ? 'ag-cell-red' : '', 
          cellStyle: { textAlign: 'right' },
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', 
          aggFunc: 'sum', columnGroupShow: 'open',
        },
        { 
          headerName: "TACOS %", field: "tacos_sb", sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          cellClass: params => params.value <= -20 ? 'ag-cell-red' : '', 
          cellStyle: { textAlign: 'right' }, 
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', 
          aggFunc: 'sum' 
        },
        { headerName: "Commission %", field: "fba_commission_perc", sortable: true, filter: 'agNumberColumnFilter', width: 110, cellStyle: { textAlign: 'right' }, valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US', {maximumFractionDigits: 0})}` : '', aggFunc: 'sum', columnGroupShow: 'open' },
      ]
    },
  ]