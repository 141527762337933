import React from 'react';
import { Box } from '@mui/material';

const ImagePreview = ({ src }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        maxWidth: '300px',
        maxHeight: '300px',
        border: '2px solid #ccc',
        borderRadius: '4px',
        backgroundColor: 'white',
        padding: '4px',
        pointerEvents: 'none',
      }}
    >
      <img
        src={src}
        alt="Preview"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default ImagePreview; 