import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ImagePreview from './ImagePreview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const DAM = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [previewInfo, setPreviewInfo] = useState({ show: false, src: '', x: 0, y: 0 });

  const fetchData = useCallback(() => {
    console.log('Initiating DAM data fetch');
    fetch('/api/dam/get_dam')
      .then(response => {
        console.log('Response headers:', response.headers);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          if (data.length > 0) {
            console.log('First row sample:', data[0]);
          }
          setRowData(data);
        } else {
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching DAM data:', error);
        console.error('Error details:', error.stack);
        setError(error.message);
        setRowData([]);
      });
  }, []);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const LinkCellRenderer = (props) => {
    const handleMouseEnter = (e) => {
      if (props.value) {
        setPreviewInfo({
          show: true,
          src: props.value,
          x: e.clientX + 10,
          y: e.clientY + 10,
        });
      }
    };

    const handleMouseLeave = () => {
      setPreviewInfo({ show: false, src: '', x: 0, y: 0 });
    };

    const handleMouseMove = (e) => {
      if (previewInfo.show) {
        setPreviewInfo(prev => ({
          ...prev,
          x: e.clientX + 10,
          y: e.clientY + 10,
        }));
      }
    };

    return props.value ? (
      <a
        href={props.value}
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        {props.value}
      </a>
    ) : null;
  };

  const CopyButtonRenderer = (props) => {
    const handleCopy = () => {
      const paths = Object.entries(props.node.data)
        .filter(([key, value]) => key.startsWith('path') && value)
        .map(([_, value]) => value)
        .join('\t');

      navigator.clipboard.writeText(paths).then(() => {
        console.log('Paths copied to clipboard');
      });
    };

    return (
      <Tooltip title="Copy all paths">
        <IconButton 
          size="small" 
          onClick={handleCopy}
          sx={{ 
            '&:hover': { 
              backgroundColor: 'rgba(0, 0, 0, 0.04)' 
            } 
          }}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  };

  const columns = useMemo(() => {
    if (rowData.length === 0) return [];
    
    const copyButtonColumn = {
      headerName: '',
      field: 'copyButton',
      width: 65,
      cellRenderer: CopyButtonRenderer,
      sortable: false,
      filter: false,
      pinned: 'left',
    };

    const orderedColumns = ['line', 'folder', 'sku', ...Array.from({length: 20}, (_, i) => `path${i+1}`)];
    const standardColumns = orderedColumns.map(key => {
      let headerName;
      let cellRenderer;
      let width;
      let wrapText = false;
      let autoHeight = false;
      
      switch(key) {
        case 'line':
          headerName = 'Line';
          width = 90;
          break;
        case 'folder':
          headerName = 'Folder';
          width = 90;
          break;
        case 'sku':
          headerName = 'SKU';
          break;
        default:
          headerName = `Path ${key.slice(4)}`;
          cellRenderer = LinkCellRenderer;
      }
      
      return {
        headerName,
        field: key,
        sortable: true,
        filter: true,
        resizable: true,
        cellRenderer: cellRenderer,
        width: width,
      };
    }).filter(col => rowData[0].hasOwnProperty(col.field));

    return [copyButtonColumn, ...standardColumns];
  }, [rowData]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: null,
    };
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Digital Asset Management</Typography>
        <Box>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <>
          <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columns}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              enableRangeSelection={true}
              copyHeadersToClipboard={false}
              pagination={true}
              paginationPageSize={50}
              sideBar={sideBar}
              rowHeight={25}
            />
          </div>
          {previewInfo.show && (
            <ImagePreview
              src={previewInfo.src}
              style={{
                left: `${previewInfo.x}px`,
                top: `${previewInfo.y}px`,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DAM;