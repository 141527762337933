// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import { Button, Box, Typography, TextField } from '@mui/material';
// import axios from 'axios';

// const SBDashboard = () => {
//   const [dashboardData, setDashboardData] = useState([]);
//   const gridRef = useRef(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');

//   const fetchDashboardData = async () => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const params = {};
//       if (startDate) params.start_date = startDate;
//       if (endDate) params.end_date = endDate;
      
//       console.log("Sending request with params:", params);
//       const response = await axios.get('/api/sb_dashboard/', { params });
//       console.log("Data received in frontend:", response.data.slice(0, 5));  // Log first 5 rows
//       console.log("Total rows received:", response.data.length);
//       setDashboardData(response.data);
//     } catch (error) {
//       console.error('Error fetching dashboard data:', error);
//       setError('Error fetching dashboard data. Please try again.');
//     } 
//   };

//   useEffect(() => {
//     fetchDashboardData();
//   }, []);

//   const handleExportCSV = useCallback(() => {
//     gridRef.current.api.exportDataAsCsv();
//   }, []);

//   const handleClearFilters = useCallback(() => {
//     gridRef.current.api.setFilterModel(null);
//     setStartDate('');
//     setEndDate('');
//   }, []);

//   const handleApplyDateFilter = () => {
//     fetchDashboardData();
//   };

//   const columns = [
//     { headerName: "Date", field: "formatted_date", sortable: true, filter: true, width: 200 },
//     { headerName: "Country", field: "marketplace", sortable: true, filter: true, width: 200 },
//     { headerName: "SKU", field: "SKU", sortable: true, filter: true, width: 200 },
//     { headerName: "Line", field: "Line", sortable: true, filter: true, width: 100 },
//     { headerName: "Size", field: "Size", sortable: true, filter: true, width: 200 },
//     { headerName: "Color", field: "color", sortable: true, filter: true, width: 200 },
//     { headerName: "Pack", field: "pcs_pack", sortable: true, filter: true, width: 100 },
//     { headerName: "Sales", field: "sales", sortable: true, filter: true, width: 200 },
//     { headerName: "Sold", field: "units_sold", sortable: true, filter: true, width: 200 },
//     { headerName: "Returns", field: "returns", sortable: true, filter: true, width: 200 },
//     { headerName: "Return Cost", field: "return_cost", sortable: true, filter: true, width: 200 },
//     { headerName: "Discounts", field: "PromoValue", sortable: true, filter: true, width: 200 },
//     { headerName: "PPC", field: "Adv_Fees", sortable: true, filter: true, width: 200 },
//     { headerName: "Gift Wrap", field: "GiftWrap", sortable: true, filter: true, width: 200 },
//     { headerName: "Shipping", field: "Shipping", sortable: true, filter: true, width: 200 },
//     { headerName: "Amazon Fees", field: "AmazonFees", sortable: true, filter: true, width: 200 },
//     { headerName: "COGs", field: "CostOfGoods", sortable: true, filter: true, width: 200 },
//     { headerName: "VAT", field: "VAT", sortable: true, filter: true, width: 200 },
//     { headerName: "Net Profit", field: "NetProfit", sortable: true, filter: true, width: 200 }
//   ];

//   return (
//     <div style={{ padding: '10px' }}>
//       <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//         <Typography variant="h4">Sellerboard Dashboard</Typography>
//         <Box>
//           <TextField
//             label="Start Date"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             style={{ marginRight: '10px' }}
//           />
//           <TextField
//             label="End Date"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             style={{ marginRight: '10px' }}
//           />
//           <Button 
//             variant="contained" 
//             color="primary" 
//             onClick={handleApplyDateFilter}
//             style={{ marginRight: '10px' }}
//           >
//             Apply Filter
//           </Button>
//           <Button 
//             variant="contained" 
//             color="secondary" 
//             onClick={handleClearFilters} 
//             style={{ marginRight: '10px' }}
//           >
//             Clear Filters
//           </Button>
//           <Button variant="contained" color="primary" onClick={handleExportCSV}>
//             Export CSV
//           </Button>
//         </Box>
//       </Box>
//       {error ? (
//         <p style={{ color: 'red' }}>{error}</p>
//       ) : dashboardData.length === 0 ? (
//         <p>No data available.</p>
//       ) : (
//         <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
//           <AgGridReact
//             ref={gridRef}
//             rowData={dashboardData}
//             columnDefs={columns}
//             onGridReady={(params) => {
//               params.api.sizeColumnsToFit();
//             }}
//             domLayout="autoHeight"
//             pagination={true}
//             paginationPageSize={50}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default SBDashboard;


import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, TextField, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SBDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const gridRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const customCellRenderer = (params) => {
    return (
      <Typography variant="h6" style={{ margin: 0 }}>
        {params.value}
      </Typography>
    );
  };

  const columns = useMemo(() => [
    { field: "formatted_date", headerName: "Date", sortable: true, filter: true, width: 200, cellRenderer: customCellRenderer },
    { field: "marketplace", headerName: "Country", sortable: true, filter: true, width: 200, cellRenderer: customCellRenderer },
    { field: "SKU", headerName: "SKU", sortable: true, filter: true, width: 200, cellRenderer: customCellRenderer },
    { field: "Line", headerName: "Line", sortable: true, filter: true, width: 100, cellRenderer: customCellRenderer },
    { field: "Size", headerName: "Size", sortable: true, filter: true, width: 200, cellRenderer: customCellRenderer },
    { field: "color", headerName: "Color", sortable: true, filter: true, width: 200, cellRenderer: customCellRenderer },
    { field: "pcs_pack", headerName: "Pack", sortable: true, filter: true, width: 100, cellRenderer: customCellRenderer },
    { field: "sales", headerName: "Sales", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "units_sold", headerName: "Sold", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "returns", headerName: "Returns", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "return_cost", headerName: "Return Cost", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "PromoValue", headerName: "Discounts", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "Adv_Fees", headerName: "PPC", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "GiftWrap", headerName: "Gift Wrap", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "Shipping", headerName: "Shipping", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "AmazonFees", headerName: "Amazon Fees", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "CostOfGoods", headerName: "COGs", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "VAT", headerName: "VAT", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer },
    { field: "NetProfit", headerName: "Net Profit", sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 150, cellRenderer: customCellRenderer }
  ], []);

  const fetchDashboardData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = new URLSearchParams();
      if (startDate) params.append('start_date', startDate);
      if (endDate) params.append('end_date', endDate);
      
      console.log("Sending request with params:", params.toString());
      const response = await fetch(`/api/sb_dashboard/?${params.toString()}`);
      console.log("Response status:", response.status);
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log("Data received in frontend:", data.slice(0, 5));
      console.log("Total rows received:", data.length);
      setDashboardData(data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Error fetching dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    document.title = "Sellerboard Dashboard | Encasa HQ";
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setStartDate('');
    setEndDate('');
  }, []);

  const handleApplyDateFilter = useCallback(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Sellerboard Dashboard</Typography>
        <Box>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleApplyDateFilter}
            style={{ marginRight: '10px' }}
          >
            Apply Filter
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={dashboardData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={[50, 100, 500, 1000]}
            rowHeight={25}
          />
        </div>
      )}
    </div>
  );
};

export default SBDashboard;

