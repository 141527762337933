export const getTableData = (gridApi) => {
  // Get the column groups and their children
  const columnGroups = {};
  gridApi.getColumnDefs().forEach(col => {
    if (col.children) {
      columnGroups[col.headerName] = col.children.map(child => child.field);
    }
  });

  // Get the filtered rows
  const filteredRows = [];
  gridApi.forEachNodeAfterFilter(node => {
    if (node.data) {
      filteredRows.push(node.data);
    }
  });

  // Structure the data according to column groups
  const structuredData = filteredRows.map(row => {
    const structuredRow = {};
    
    for (const [groupName, fields] of Object.entries(columnGroups)) {
      structuredRow[groupName] = {};
      fields.forEach(field => {
        structuredRow[groupName][field] = row[field];
      });
    }

    // Add non-grouped columns
    gridApi.getColumnDefs().forEach(col => {
      if (!col.children && col.field) {
        structuredRow[col.field] = row[col.field];
      }
    });

    return structuredRow;
  });

  return structuredData;
}; 