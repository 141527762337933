import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import { columns as lineDetailsColumns } from '../line_details/lineDetailsColumns.js';
import { columnSets as cospColumns } from '../cosp/cospColumns.js';
import AGGrid_Options from "../../components/global/AGGrid_Options";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import { columns as returnsColumns } from '../returns/returnsColumns.js';
import { columns as lineMonthlyColumns } from '../line_monthly/lineMonthlyColumns.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getTableData } from '../../utils/gridUtils';

const ProductLines = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [countries, setCountries] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLine, setSelectedLine] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');

  // References for each grid
  const lineDetailsGridRef = useRef(null);
  const cospGridRef = useRef(null);
  const returnsGridRef = useRef(null);
  const lineMonthlyGridRef = useRef(null);

  // State for each dataset
  const [lineDetailsData, setLineDetailsData] = useState([]);
  const [cospData, setCospData] = useState([]);
  const [returnsData, setReturnsData] = useState([]);
  const [lineMonthlyData, setLineMonthlyData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCountriesAndLines();
  }, []);

  useEffect(() => {
    if (selectedCountry && selectedLine) {
      console.log('Fetching data for:', { selectedCountry, selectedLine });
      const fetchAllData = async () => {
        try {
          await fetchLineDetailsData();
          await delay(500); // 500ms delay between requests
          await fetchCOSPData();
          await delay(500);
          await fetchReturnsData();
          await delay(500);
          await fetchLineMonthlyData();
        } catch (error) {
          console.error('Error in fetch sequence:', error);
        }
      };
      fetchAllData();
    }
  }, [selectedCountry, selectedLine]);

  const fetchOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const fetchCountriesAndLines = async () => {
    try {
      const response = await fetch('/api/line-details/get-countries-lines', fetchOptions);
      if (!response.ok) {
        const text = await response.text();
        console.error('Countries and lines response not ok:', response.status, text);
        setError(`Failed to fetch countries and lines: ${response.status} ${text}`);
        return;
      }
      const data = await response.json();
      const uniqueCountries = [...new Set(data.map(item => item.country))];
      setCountries(uniqueCountries);
      setLines([...new Set(data.map(item => item.line))]);
    } catch (error) {
      console.error('Error fetching countries and lines:', error);
      setError(error.message);
    }
  };

  const fetchLineDetailsData = async () => {
    try {
      const response = await fetch(`/api/line-details/get-details-filtered?country=${selectedCountry}&line=${selectedLine}`, fetchOptions);
      if (!response.ok) {
        const text = await response.text();
        console.error('Line details response not ok:', response.status, text);
        setError(`Failed to fetch line details: ${response.status} ${text}`);
        setLineDetailsData([]);
        return;
      }
      const data = await response.json();
      setLineDetailsData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching line details:', error);
      setError(error.message);
      setLineDetailsData([]);
    }
  };

  const fetchCOSPData = async () => {
    try {
      const response = await fetch(`/api/cosp/get_cosp_filtered?country=${selectedCountry}&line=${selectedLine}`, fetchOptions);
      if (!response.ok) {
        const text = await response.text();
        console.error('COSP response not ok:', response.status, text);
        setError(`Failed to fetch COSP data: ${response.status} ${text}`);
        setCospData([]);
        return;
      }
      const data = await response.json();
      setCospData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching COSP data:', error);
      setError(error.message);
      setCospData([]);
    }
  };

  const fetchReturnsData = async () => {
    try {
      const response = await fetch(`/api/orders/returns_reasons_filtered?country=${selectedCountry}&line=${selectedLine}`, fetchOptions);
      if (!response.ok) {
        const text = await response.text();
        console.error('Returns response not ok:', response.status, text);
        setError(`Failed to fetch returns data: ${response.status} ${text}`);
        setReturnsData([]);
        return;
      }
      const data = await response.json();
      setReturnsData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching returns data:', error);
      setError(error.message);
      setReturnsData([]);
    }
  };

  const fetchLineMonthlyData = async () => {
    try {
      const response = await fetch(`/api/line/get_combined_line_data_filtered?country=${selectedCountry}&line=${selectedLine}`, fetchOptions);
      if (!response.ok) {
        const text = await response.text();
        console.error('Line monthly response not ok:', response.status, text);
        setError(`Failed to fetch line monthly data: ${response.status} ${text}`);
        setLineMonthlyData([]);
        return;
      }
      const data = await response.json();
      setLineMonthlyData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching line monthly data:', error);
      setError(error.message);
      setLineMonthlyData([]);
    }
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleLineChange = (event) => {
    setSelectedLine(event.target.value);
  };

  const handleQuickFilterChange = useCallback((filter) => {
    setQuickFilterText(filter);
  }, []);

  const handleExportCSV = useCallback((gridRef) => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleCopyTableJson = (gridRef) => {
    if (!gridRef.current || !gridRef.current.api) return;
    
    const tableData = getTableData(gridRef.current.api);
    navigator.clipboard.writeText(JSON.stringify(tableData, null, 2))
      .then(() => {
        console.log('Table JSON copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy table JSON:', err);
      });
  };

  // Helper function to render each grid
  const renderGrid = (title, data, columns, gridRef, height = 400) => (
    <Box mb={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleCopyTableJson(gridRef)}
            size="small"
            startIcon={<ContentCopyIcon />}
            sx={{ mr: 1 }}
          >
            Copy Table JSON
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => handleExportCSV(gridRef)}
            size="small"
          >
            Export CSV
          </Button>
        </Box>
      </Box>
      <div className="ag-theme-alpine" style={{ height: height, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          {...AGGrid_Options}
          sideBar={AGGrid_Sidebar}
          quickFilterText={quickFilterText}
        />
      </div>
    </Box>
  );

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Product Lines</Typography>
        <Box display="flex" alignItems="center">
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              label="Country"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {countries.map(country => (
                <MenuItem key={country} value={country}>{country}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <FormControl variant="outlined" size="small" style={{ marginRight: '10px', minWidth: '150px' }}>
            <InputLabel>Line</InputLabel>
            <Select
              value={selectedLine}
              onChange={handleLineChange}
              label="Line"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {lines.map(line => (
                <MenuItem key={line} value={line}>{line}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <QuickFilter onFilterChange={handleQuickFilterChange} />
        </Box>
      </Box>

      {error ? (
        <Typography color="error" variant="h6">Error: {error}</Typography>
      ) : (
        <div style={{ flex: 1 }}>
          {renderGrid("Line Details", lineDetailsData, lineDetailsColumns, lineDetailsGridRef, 300)}
          {renderGrid("SKU Details", cospData, cospColumns.default, cospGridRef, 600)}
          {renderGrid("Line Historic", lineMonthlyData, lineMonthlyColumns, lineMonthlyGridRef, 1000)}
          {renderGrid("Returns", returnsData, returnsColumns, returnsGridRef, 600)}
        </div>
      )}
    </div>
  );
};

export default ProductLines;
