import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const Variations = () => {
  const [asins, setAsins] = useState('');
  const [country, setCountry] = useState('US');
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const gridRef = useRef(null);

  const columnDefs = [
    { field: 'asin', headerName: 'ASIN', maxWidth: 130 },
    { field: 'brand', headerName: 'Brand', maxWidth: 120 },
    { field: 'item_name', headerName: 'Item Name', width: 250 },
    { field: 'color', headerName: 'Color', maxWidth: 120 },
    { field: 'size', headerName: 'Size', maxWidth: 170 },
    { field: 'packageQuantity', headerName: 'Pcs/pk', maxWidth: 130 },
  ];

  const handleFetchVariations = () => {
    setLoading(true);
    const processedAsins = asins
      .split(/[\n, ]/)
      .map(asin => asin.trim())
      .filter(asin => asin);
    fetch('/api/amazon_tools/variations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ child_asins: processedAsins, country: country }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          gridRef.current.api.setGridOption('rowData', data);
        } else {
          console.error('Unexpected response format:', data);
        }
      })
      .catch((error) => console.error('Error fetching variations:', error))
      .finally(() => setLoading(false));
  };

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Get Variations</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} height="calc(100vh - 80px)">
        <Box display="flex" flexDirection="column" gap={1} width="250px">
          <FormControl variant="outlined" sx={{ width: '250px', mb: 1 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              label="Country"
            >
              <MenuItem value="US">US</MenuItem>
              <MenuItem value="CA">CA</MenuItem>
              <MenuItem value="UK">UK</MenuItem>
              <MenuItem value="DE">DE</MenuItem>
              <MenuItem value="FR">FR</MenuItem>
              <MenuItem value="IT">IT</MenuItem>
              <MenuItem value="ES">ES</MenuItem>
              <MenuItem value="IN">IN</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="ASINs"
            multiline
            rows={8}
            variant="outlined"
            value={asins}
            onChange={(e) => setAsins(e.target.value)}
            sx={{ width: '250px', mb: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchVariations}
            sx={{ width: '250px' }}
            disabled={loading}
          >
            {loading ? 'Fetching...' : 'Fetch All Variations'}
          </Button>
        </Box>

        <Box flex={1}>
          <div id="myGrid" className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              {...AGGrid_Options}
              sideBar={AGGrid_Sidebar}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Variations;