import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, useTheme, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import QuickFilter from '../../components/global/QuickFilter'; // Import QuickFilter component

const LineMonthly = () => {
  const theme = useTheme();
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const gridRef = useRef(null);

  useEffect(() => {
    document.title = "Line Monthly | Encasa HQ";
    console.log('Initiating Line Monthly data fetch');
    fetch('/api/line/get_combined_line_data')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          const computedData = data.map(row => {
            const negL12 = Array.from({ length: 12 }, (_, i) => row[`month_${i + 1}`] || 0)
                          .filter(value => value < 0).length;
            const posL12 = Array.from({ length: 12 }, (_, i) => row[`month_${i + 1}`] || 0)
                          .filter(value => value > 0).length;
            return { ...row,  negL12, posL12 };
          });
          setRowData(computedData);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching Line Monthly data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  const formatValue = (params, defaultDecimalPlaces = 0) => {
    if (!params.data || !params.data.metric) {
      return params.value ? params.value.toLocaleString('en-US', { 
        maximumFractionDigits: defaultDecimalPlaces, 
        minimumFractionDigits: defaultDecimalPlaces 
      }) : '';
    }
  
    const metric = params.data.metric; 
    const shouldFormatToOneDecimal = metric === "CVR %" || metric === "CTR %" ; 
  
    const decimalPlaces = shouldFormatToOneDecimal ? 1 : defaultDecimalPlaces;
  
    return params.value ? params.value.toLocaleString('en-US', {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces
    }) : '';
  };

  const columns = [
    {
      headerName: 'Revenue Details',
      children: [
    { headerName: "Country",  field: "country", sortable: true,  filter: true,  width: 120,  pinned: 'left', enableRowGroup: true,
      getQuickFilterText: params => params.value },
    { headerName: "Line",  field: "line", sortable: true,  filter: true,  width: 120,  pinned: 'left', enableRowGroup: true,
      getQuickFilterText: params => params.value },
    { headerName: "Link", field: "link", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true,cellStyle: { textAlign: 'center' },
      cellRenderer: params => { if (!params.value) { return null; } const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
      return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'}  }, '🔗');  } },  
    { headerName: "Metric",  field: "metric",  sortable: true,  filter: true,  width: 150,  pinned: 'left', enableRowGroup: true,
      getQuickFilterText: params => params.value }]},
    {
      headerName: 'Line Info',
      children: [
    { headerName: "DRI",  field: "dri",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value },    
    { headerName: "Region",  field: "channel_abb",  sortable: true,  filter: true,  width: 125,  enableRowGroup: true, getQuickFilterText: params => params.value},
    { headerName: "Channel",  field: "channel",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    { headerName: "Group",  field: "group",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
    { headerName: "Segment",  field: "segment",  sortable: true,  filter: true,  width: 100,  enableRowGroup: true, getQuickFilterText: params => params.value,columnGroupShow: 'open' },
      ]},

    {
      headerName: 'Monthly Details',
      children: [
        { headerName: "L12",  field: "L12", 
          sortable: true,   filter: 'agNumberColumnFilter',  width: 120,  enableRowGroup: true,
          cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit'  }),
          valueFormatter: params => formatValue(params), getQuickFilterText: params => params.value
        },
        { headerName: "Neg L12",  field: "negL12",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, cellStyle: { textAlign: 'right' },columnGroupShow : 'open',
          getQuickFilterText: () => ''  
          },
        { headerName: "Pos L12",  field: "posL12",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, cellStyle: { textAlign: 'right' },columnGroupShow : 'open',
          getQuickFilterText: () => '' 
        },    
    ...Array.from({ length: 12 }, (_, i) => {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - i);
      const month = currentDate.toLocaleString('default', { month: 'short' });
      const year = currentDate.getFullYear().toString().slice(-2);
      const monthLabel = `${month} ${year}`;
      return {
        headerName: monthLabel,
        field: `month_${i + 1}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 100,
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value < 0 ? '#AA0000' : 'inherit' 
        }),
        columnGroupShow: i < 6 ? undefined : 'open',
        aggFunc: 'sum',
        valueFormatter: params => formatValue(params),
        getQuickFilterText: () => '' 
      };
    })]},
    {
      headerName: 'Day-Wise Details',
      children: [
    { headerName: "l7",  field: "l7", sortable: true,   filter: 'agNumberColumnFilter',  width: 120,  enableRowGroup: true,
      cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit'  }),
      valueFormatter: params => formatValue(params),getQuickFilterText: params => params.value
    },
    { headerName: "p7",  field: "p7", sortable: true,   filter: 'agNumberColumnFilter',  width: 120,  enableRowGroup: true,cellStyle: { textAlign: 'right' },
      valueFormatter: params => formatValue(params),getQuickFilterText: params => params.value, columnGroupShow : 'open'
    },    
    { headerName: 'Delta', field: 'delta', sortable: true, filter: 'agNumberColumnFilter', width: 100, cellStyle: params => ({  textAlign: 'right',  color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black'  }),
    valueGetter: params => { if (!params.data) return null; return (params.data.l7 || 0) - (params.data.p7 || 0); },
    valueFormatter: params => { if (params.value == null) return ''; const formattedValue = Math.abs(params.value).toLocaleString('en-US');
    return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
    }, aggFunc: 'sum', headerTooltip: 'Last Week - P7'
    },
    { 
      headerName: "l30",  field: "l30",  sortable: true, filter: 'agNumberColumnFilter', width: 100, enableRowGroup: true, 
      cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit'  }),
      valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '', getQuickFilterText: params => params.value 
    },
    ...Array.from({ length: 59 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (i + 1));  
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      return {
        headerName: formattedDate,
        field: `day_${i + 2}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 100,
        cellStyle: params => ({
          textAlign: 'right',
          color: params.value < 0 ? '#AA0000' : 'inherit' 
        }),
        columnGroupShow: i < 15 ? undefined : 'open',
        aggFunc: 'sum',
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        getQuickFilterText: () => '' // Exclude from quick filter
      };
    })]}
  ];

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((filter) => {
    setAppliedQuickFilter(filter);
  }, []);

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: null,
  }), []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Historic</Typography>
        <Box display="flex" alignItems="center">
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              cellRenderer: 'agCellRendererHtml',
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={[50, 100, 500, 1000]}
            rowHeight={25}
            grandTotalRow="bottom"
            groupDefaultExpanded={0} // Collapse all groups by default
            autoGroupColumnDef={{
              headerName: 'Group',
              minWidth: 250,
              cellRendererParams: {
                suppressCount: true,
              },
            }}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

export default LineMonthly;