export const columns = [
  { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 125 },
  { headerName: "Line", field: "Line", sortable: true, filter: true, width: 125 },
  { headerName: "Color", field: "Color_Design", sortable: true, filter: true, width: 200 },
  { headerName: "Size", field: "Size", sortable: true, filter: true, width: 125 },
  { headerName: "Pcs Pack", field: "pcs_pack", sortable: true, filter: true, width: 120 },
  { 
    headerName: "Return Date", 
    field: "return_date", 
    sortable: true, 
    filter: true, 
    width: 150, 
    valueFormatter: params => {
      if (!params.value) return '';
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) return ''; // Handle invalid dates
        return date.toISOString().split('T')[0];
      } catch (error) {
        console.warn('Invalid date value:', params.value);
        return '';
      }
    }
  },
  { headerName: "Reasons", field: "reason", sortable: true, filter: true, width: 180 },
  { headerName: "Customer Comments", field: "customer_comments", sortable: true, filter: true, width: 400 }
]; 