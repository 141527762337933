import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columns } from './scratchpadFeedColumns';
import gridOptionsDefault from '../../components/global/AGGrid_Options';
import AGGridSidebar from '../../components/global/AGGrid_Sidebar';

const ScratchpadFeed = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await fetch('/api/scratchpad/notes/all');
        if (!response.ok) {
          throw new Error('Failed to fetch notes');
        }
        const data = await response.json();
        setNotes(data);
      } catch (error) {
        console.error('Error fetching notes:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Scratchpad Feed</Typography>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            rowData={notes}
            columnDefs={columns}
            defaultColDef={gridOptionsDefault.defaultColDef}
            {...gridOptionsDefault}
            sideBar={AGGridSidebar}
            animateRows={true}
          />
        </div>
      )}
    </div>
  );
};

export default ScratchpadFeed; 