import React, { useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const SkuFulfilmentFee = () => {
  const [sku, setSku] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [rowData, setRowData] = useState([]);
  const [fetchFeedback, setFetchFeedback] = useState({ message: '', style: {} });
  const gridRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const yy = String(date.getFullYear()).slice(-2);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yy}-${mm}-${dd}`;
  };

  const columnDefs = [
    { 
      field: 'ob_date', 
      headerName: 'Date', 
      width: 100,
      valueFormatter: (params) => formatDate(params.value),
      cellStyle: { textAlign: 'right' }
    },
    { field: 'country_code', headerName: 'Country', width: 100 },
    { field: 'currency', headerName: 'Currency', width: 100 },
    { field: 'line', headerName: 'Line', width: 100 },
    { field: 'size', headerName: 'Size', width: 100 },
    { field: 'Color_Design', headerName: 'Color', width: 100 },
    { field: 'pcs_pack', headerName: 'Pcs/Pack', width: 100, cellStyle: { textAlign: 'right' } },
    { field: 'order_id', headerName: 'Order ID', width: 200 },
    { field: 'quantity_shipped', headerName: 'Qty', width: 100, cellStyle: { textAlign: 'right' } },
    { 
      field: 'FBAPerUnitFulfillmentFee', 
      headerName: 'Fulfillment Fee', 
      width: 150, 
      cellStyle: { textAlign: 'right' },
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
  ];

  const handleFetchData = () => {
    setFetchFeedback({ message: 'Fetching data...', style: { color: 'blue' } });
    fetch('/api/fulfilment_fee/sku', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sku: sku.trim(), currency: currency }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          setFetchFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
        } else {
          setFetchFeedback({ message: 'Unexpected response format', style: { color: 'orange' } });
        }
      })
      .catch((error) => {
        setFetchFeedback({ message: 'Error fetching data', style: { color: 'red' } });
      });
  };

  const handleExportCSV = () => {
    gridRef.current.api.exportDataAsCsv();
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">SKU Fulfilment Fee</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} height="calc(100vh - 100px)">
        {/* Left Panel */}
        <Box display="flex" flexDirection="column" gap={1} width="200px">
          <FormControl variant="outlined" sx={{ width: '200px', mb: 1 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Currency"
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="INR">INR</MenuItem>
              <MenuItem value="JPY">JPY</MenuItem>
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="MXN">MXN</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            label="SKU"
            variant="outlined"
            value={sku}
            onChange={(e) => setSku(e.target.value)}
            sx={{ width: '200px', mb: 1 }}
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            sx={{ width: '200px' }}
          >
            Fetch Data
          </Button>
          
          <Typography variant="body2" sx={{ ...fetchFeedback.style, mt: 1 }}>
            {fetchFeedback.message}
          </Typography>
        </Box>

        {/* Right Panel */}
        <Box flex={1}>
          <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              {...AGGrid_Options}
              sideBar={AGGrid_Sidebar}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default SkuFulfilmentFee;
