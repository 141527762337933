import React, { useState, useEffect, useRef, useMemo, createElement, useCallback } from 'react';
import { Box, Typography, useTheme, Button, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AlertPillsCellRenderer from '../../components/global/AlertPillsCellRenderer.jsx';
import { getRatioValue } from '../../components/global/getRatioValue.jsx'; // Import the function
import QuickFilter from '../../components/global/QuickFilter.jsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SP = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const gridRef = useRef(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const customCellRenderer = (params) => {
    return (
      <Typography variant="h6" style={{ margin: 0 }}>
        {params.value}
      </Typography>
    );
  };

  const StatusCellRenderer = (props) => {
    const statusValue = props.value;
    console.log("Status value:", statusValue); // Log the status value
    if (statusValue === 'live') {
      return <span className="ag-icon ag-icon-tick" style={{ color: 'green' }}></span>;
    } else if (statusValue === 'discontinued') {
      return <span className="ag-icon ag-icon-cross" style={{ color: 'red' }}></span>;
    }
    return statusValue; // Return the original value if it's neither 'live' nor 'discontinued'
  };

  const [columnDefs] = useState(() => {
    const baseDefs = [
      { field: 'sku', headerName: 'SKU', sortable: true, filter: true, width: 150, pinned: 'left', enableRowGroup: true, cellRenderer: customCellRenderer},
      { 
        headerName: "Link",
            field: "channel_abb", 
            width: 85,
            //pinned: 'left',
            cellRenderer: params => {
                if (!params.data || !params.data.asin1) {
                    return null;
                }
                const url = `https://www.${params.value}/dp/${params.data.asin1}`;
    return <a href={url} target="_blank" rel="noopener noreferrer">{'>'}</a>;
        }
      },
      { 
        headerName: "ASIN", 
        field: "asin1", 
        sortable: true, 
        filter: 'agTextColumnFilter', 
        width: 135, 
        columnGroupShow: 'open', 
        cellRenderer: customCellRenderer,
        filterParams: {
            filterOptions: [
                {
                    displayKey: 'multipleAsins',
                    displayName: 'Multiple ASINs',
                    predicate: ([filterValue], cellValue) => {
                        if (!filterValue) return true;
                        const asins = filterValue
                            .replace(/\s*,\s*/g, ' ')  // Replace commas with spaces
                            .split(/\s+/)              // Split on one or more spaces
                            .filter(asin => asin !== '');
                        return asins.includes(cellValue);
                    },
                    numberOfInputs: 1,
            }
          ],
        }
      },
      { field: 'channel_abb', headerName: 'Region', sortable: true, filter: true, width: 135, pinned: 'left', enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'production', headerName: 'Prod', sortable: true, filter: true , width: 100, pinned: 'left', enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'categories', headerName: 'Cat', sortable: true, filter: true , width: 90, pinned: 'left', enableRowGroup: true, cellRenderer: customCellRenderer},
      { 
        field: 'days_reorder', 
        headerName: 'Days Reorder', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        pinned: 'left', 
        aggFunc: 'sum', 
        valueFormatter: params => {
            // Show nothing for 0 or negative values
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
          }, 
        cellRenderer: params => {
            // Ensure cellRenderer also respects the valueFormatter logic
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
        }
      },
      { 
        field: 'new_reorder', 
        headerName: 'Reorder', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        pinned: 'left', 
        aggFunc: 'sum', 
        valueFormatter: params => {
            // Show nothing for 0 or negative values
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
          }, 
        cellRenderer: params => {
            // Ensure cellRenderer also respects the valueFormatter logic
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        filter: true,
        width: 100,
        pinned: 'left',
        cellRenderer: StatusCellRenderer
      },
      { 
        field: 'for_po', 
        headerName: 'For PO', 
        sortable: true, 
        cellRenderer: customCellRenderer,
        filter: 'agNumberColumnFilter', 
        editable: true, 
        type: 'numericColumn', 
        width: 100, 
        pinned: 'left', 
        aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
              return params.data.for_po !== undefined 
                ? params.data.new_reorder 
                : (params.data.new_reorder > 0 ? params.data.new_reorder : undefined);
          }
          return undefined;
        },
        valueSetter: params => {
          if (params.data) {
            params.data.for_po = params.newValue;
            return true;
          }
          return false;
        },
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '',
        cellStyle: params => {
          if (params.data && params.data.for_po !== undefined && params.data.for_po !== params.data.new_reorder) {
            return { backgroundColor: '#FFFFA0' }; // Pale light yellow
          }
          return null;
        }
      },
      { field: 'line', headerName: 'Line', sortable: true, filter: true, width: 100, enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'size', headerName: 'Size', sortable: true, filter: true, width: 100, enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'Color_Design', headerName: 'Color', sortable: true, filter: true, width: 150, enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'pcs_pack', headerName: 'Pcs', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, enableRowGroup: true, cellRenderer: customCellRenderer},
      { field: 'last_day_purchase', headerName: 'Last Sold', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer},
      { field: 'launch_date', headerName: 'Launch Date', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer},
      { field: 'instock', headerName: 'In Stock', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      { field: 'inbound', headerName: 'Inbound', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      {
        headerName: 'Inbound POs',
        children:[
          ...Array.from({ length: 10 }, (_, i) => {
              return {
              headerName: `PO${i + 1}`,
              field: `PO${i + 1}`,
            sortable: true,
            filter: 'agNumberColumnFilter',
            width: 100,
            columnGroupShow: i < 4 ? undefined : 'open',
            cellStyle: { textAlign: 'right' },
            valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : ''
          };
        })]
      },
      {
        field: 'ma',
        headerName: 'MA',
        sortable: true,
        filter: 'agNumberColumnFilter',
        type: 'numericColumn',
        width: 100,
        cellRenderer: params => {
          if (params.value === null || params.value === undefined) return '';
          const formattedValue = params.value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
          
          // Calculate the gradient based on the value
          // Assuming 'ma' values typically range from 0 to 100, adjust as needed
          const gradientValue = Math.min(Math.max((params.value - 0.1) / (20 - 0.1), 0), 1);
          const backgroundColor = `rgba(0, 255, 0, ${gradientValue})`;
          
          return (
            <div style={{ 
              backgroundColor, 
              width: '100%', 
              height: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'flex-end',
              paddingRight: '5px'
            }}>
              {formattedValue}
            </div>
          );
        },
        aggFunc: 'sum',
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''
      },
      { field: 'doc', headerName: 'DOC', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer},
      { field: 'l365_sold', headerName: 'L365 Sold', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      { field: 'l90_sold', headerName: 'L90 Sold', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      { 
        field: 'l30_sold', 
        headerName: 'L30 Sold', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        cellRenderer: params => {
          const value = params.value;
          const p30Value = params.data.P30_sold;
          const formattedValue = value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '';
          
          if (value < p30Value) {
            return (
              <span style={{ color: '#FF0000' }}>
                {formattedValue}
              </span>
            );
          }
          return formattedValue;
        },
        aggFunc: 'sum', 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' 
      },
      { field: 'P30_sold', headerName: 'P30 Sold', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' },
      { 
        field: 'l7_sold', 
        headerName: 'L7 Sold', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        cellRenderer: params => {
          const value = params.value;
          const p7Value = params.data.P7_sold;
          const formattedValue = value ? value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '';
          
          if (value < p7Value) {
            return (
              <span style={{ color: '#FF0000' }}>
                {formattedValue}
              </span>
            );
          }
          return formattedValue;
        },
        aggFunc: 'sum', 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '' 
      },
      { field: 'P7_sold', headerName: 'P7 Sold', sortable: true, filter: 'agNumberColumnFilter', type: 'numericColumn', width: 100, cellRenderer: customCellRenderer, aggFunc: 'sum', valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {minimumFractionDigits: 1, maximumFractionDigits: 1}) : '' },
      { 
        field: 'n30_oos', 
        headerName: 'N30_OOS', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        aggFunc: 'sum', 
        valueFormatter: params => {
            // Show nothing for 0 or negative values
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
          }, 
        cellRenderer: params => {
            // Ensure cellRenderer also respects the valueFormatter logic
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
        }
      },
      { 
        field: 'n60_oos', 
        headerName: 'N60_OOS', 
        sortable: true, 
        filter: 'agNumberColumnFilter', 
        type: 'numericColumn', 
        width: 100, 
        aggFunc: 'sum', 
        valueFormatter: params => {
            // Show nothing for 0 or negative values
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
          }, 
        cellRenderer: params => {
            // Ensure cellRenderer also respects the valueFormatter logic
            return params.value > 0 ? params.value.toLocaleString('en-US', { maximumFractionDigits: 0 }) : '';
        }
      },
    ];


    const futureDefs = Array.from({ length: 153 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() + (i + 1));
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      return {
        headerName: formattedDate,
        field: `_${i + 1}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        width: 100,
        cellStyle: params => {
          const prevDayField = `_${i}`;
          const prevDayValue = params.data[prevDayField];
          const currentValue = params.value;
          
          if (currentValue > prevDayValue) {
            return { backgroundColor: '#FFFF8F', textAlign: 'right' };
          }
          return { textAlign: 'right' };
        },
        cellRenderer: customCellRenderer,
        aggFunc: 'sum'
      };
    });

    return [...baseDefs, ...futureDefs];
  });

  useEffect(() => {
    document.title = "Sales Prediction | Encasa HQ";
    console.log('Initiating SP data fetch');
    setLoading(true);
    fetch('/api/sp/get_sp')
      .then(response => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Received data:', data);
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          console.log('Sample row:', data[0]); // Log the first row of data
          console.log('Unique status values:', [...new Set(data.map(item => item.status))]); // Log unique status values
          setRowData(data);
        } else {
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleExportSelectedColumns = useCallback(() => {
    const params = {
      columnKeys: ['sku', 'asin', 'channel_abb', 'production', 'line', 'size', 'Color_Design', 'pcs_pack', 'for_po'],
      fileName: 'selected_columns_export.csv',
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const sideBar = useMemo(() => {
        return {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
            },
            {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
            },
          ],
          defaultToolPanel: null,
        };
      }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilterText('');
    // Force the QuickFilter component to reset
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  const handleClearCache = useCallback(() => {
    fetch('/api/sp/clear_cache', { method: 'POST' })
      .then(response => response.json())
      .then(data => {
        console.log('Cache cleared:', data);
        // Optionally, you can refresh the data here
        // by calling the initial data fetch function
      })
      .catch(error => console.error('Error clearing cache:', error));
  }, []);

  const handleQuickFilterChange = useCallback((filterText) => {
    setQuickFilterText(filterText);
  }, []);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Sales Prediction</Typography>
        <Box>
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV} 
            style={{ marginRight: '10px' }}
          >
            Export CSV
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportSelectedColumns} 
            style={{ marginRight: '10px' }}
          >
            Export Order
          </Button>
          <Button 
            variant="contained" 
            color="warning" 
            onClick={handleClearCache} 
            style={{ marginRight: '10px' }}
          >
            Clear Cache
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            sideBar={sideBar}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            pagination={true}
            paginationPageSize={50}
            rowHeight={25}
            quickFilterText={quickFilterText}
          />
        </div>
      )}
    </div>
  );
};

export default SP;
