import React from 'react';

export const columns = [
  {
    headerName: 'Revenue Details',
    children: [
      { headerName: "Country", field: "country", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Line", field: "line", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Link", field: "link", sortable: true, filter: true, width: 100, pinned: 'left', enableRowGroup: true, cellStyle: { textAlign: 'center' },
        cellRenderer: params => { 
          if (!params.value) { return null; } 
          const url = params.value.startsWith('http') ? params.value : `https://${params.value}`; 
          return React.createElement('a', { href: url, target: '_blank', rel: 'noopener noreferrer', style: { textDecoration: 'none'} }, '🔗'); 
        }
      },
      { headerName: "Metric", field: "metric", sortable: true, filter: true, width: 150, pinned: 'left', enableRowGroup: true }
    ]
  },
  {
    headerName: 'Line Info',
    children: [
      { headerName: "DRI", field: "dri", sortable: true, filter: true, width: 100, enableRowGroup: true },
      { headerName: "Region", field: "channel_abb", sortable: true, filter: true, width: 125, enableRowGroup: true },
      { headerName: "Channel", field: "channel", sortable: true, filter: true, width: 100, enableRowGroup: true, columnGroupShow: 'open' },
      { headerName: "Group", field: "group", sortable: true, filter: true, width: 100, enableRowGroup: true, columnGroupShow: 'open' },
      { headerName: "Segment", field: "segment", sortable: true, filter: true, width: 100, enableRowGroup: true, columnGroupShow: 'open' }
    ]
  },
  {
    headerName: 'Monthly Details',
    children: [
      { 
        headerName: "L12", field: "L12", sortable: true, filter: 'agNumberColumnFilter', width: 120, enableRowGroup: true,
        cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit' }),
        valueFormatter: params => formatValue(params)
      },
      { 
        headerName: "Neg L12", field: "negL12", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        cellStyle: { textAlign: 'right' }, columnGroupShow: 'open'
      },
      { 
        headerName: "Pos L12", field: "posL12", sortable: true, filter: 'agNumberColumnFilter', width: 120, 
        cellStyle: { textAlign: 'right' }, columnGroupShow: 'open'
      },
      ...Array.from({ length: 12 }, (_, i) => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - i);
        const month = currentDate.toLocaleString('default', { month: 'short' });
        const year = currentDate.getFullYear().toString().slice(-2);
        const monthLabel = `${month} ${year}`;
        return {
          headerName: monthLabel,
          field: `month_${i + 1}`,
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: params => ({
            textAlign: 'right',
            color: params.value < 0 ? '#AA0000' : 'inherit'
          }),
          columnGroupShow: i < 6 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => formatValue(params)
        };
      })
    ]
  },
  {
    headerName: 'Day-Wise Details',
    children: [
      { 
        headerName: "l7", field: "l7", sortable: true, filter: 'agNumberColumnFilter', width: 120, enableRowGroup: true,
        cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit' }),
        valueFormatter: params => formatValue(params)
      },
      { 
        headerName: "p7", field: "p7", sortable: true, filter: 'agNumberColumnFilter', width: 120, enableRowGroup: true,
        cellStyle: { textAlign: 'right' }, valueFormatter: params => formatValue(params), columnGroupShow: 'open'
      },
      {
        headerName: 'Delta', field: 'delta', sortable: true, filter: 'agNumberColumnFilter', width: 100,
        cellStyle: params => ({ textAlign: 'right', color: params.value > 0 ? 'green' : params.value < 0 ? 'red' : 'black' }),
        valueGetter: params => {
          if (!params.data) return null;
          return (params.data.l7 || 0) - (params.data.p7 || 0);
        },
        valueFormatter: params => {
          if (params.value == null) return '';
          const formattedValue = Math.abs(params.value).toLocaleString('en-US');
          return params.value > 0 ? `+${formattedValue}` : params.value < 0 ? `-${formattedValue}` : formattedValue;
        },
        aggFunc: 'sum',
        headerTooltip: 'Last Week - P7'
      },
      { 
        headerName: "l30", field: "l30", sortable: true, filter: 'agNumberColumnFilter', width: 100, enableRowGroup: true,
        cellStyle: params => ({textAlign: 'right', color: params.value < 0 ? '#AA0000' : 'inherit' }),
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
      },
      ...Array.from({ length: 59 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (i + 1));
        const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
        return {
          headerName: formattedDate,
          field: `day_${i + 2}`,
          sortable: true,
          filter: 'agNumberColumnFilter',
          width: 100,
          cellStyle: params => ({
            textAlign: 'right',
            color: params.value < 0 ? '#AA0000' : 'inherit'
          }),
          columnGroupShow: i < 15 ? undefined : 'open',
          aggFunc: 'sum',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : ''
        };
      })
    ]
  }
];

const formatValue = (params, defaultDecimalPlaces = 0) => {
  if (!params.data || !params.data.metric) {
    return params.value ? params.value.toLocaleString('en-US', { 
      maximumFractionDigits: defaultDecimalPlaces, 
      minimumFractionDigits: defaultDecimalPlaces 
    }) : '';
  }

  const metric = params.data.metric;
  const shouldFormatToOneDecimal = metric === "CVR %" || metric === "CTR %";
  const decimalPlaces = shouldFormatToOneDecimal ? 1 : defaultDecimalPlaces;

  return params.value ? params.value.toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces
  }) : '';
}; 