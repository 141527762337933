export const createReturnsAnalysisColumns_Weekly = (data) => {
    const weekNumbers = [...new Set(data.map(item => parseInt(item.week)))].sort((a, b) => b - a); 
  
    const columns = [
      { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Line", field: "line", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true }, 
    ];
  
    weekNumbers.forEach((week) => {
      const children = [
      {headerName: `Orders`, field: `quantity_ordered_${week}`,sortable: true, filter: 'agNumberColumnFilter', width: 110, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' },aggFunc: 'sum', },
      {headerName: `Returns`,   field: `quantity_returned_${week}`,  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, 
        valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle:{textAlign:'right' }, aggFunc: 'sum', },
      {headerName: `Return Ratio`,   field: `returned_ratio_${week}`,  sortable: true,  filter: 'agNumberColumnFilter',  width: 120, 
        valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US')} %` : '', cellStyle:{textAlign:'right' }, aggFunc: 'sum', },
    ];
      
      columns.push({
        headerName: `Week ${week}`,
        children: children
      });
    });
  
    return columns;
  };

  export const createReturnsAnalysisColumns_Monthly = (data) => {
    const monthOrder = ["Dec","Nov","Oct","Sep","Aug","Jul","Jun","May","Apr","Mar","Feb","Jan"];
    
    const sortedMonths = [...new Set(data
      .map(item => item.month)
      .filter(month => month !== null && month !== undefined))] 
      .sort((a, b) => {
        const [monthA, yearA] = a.split('-');
        const [monthB, yearB] = b.split('-');
        return yearA - yearB || monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
      });
  
    const columns = [
      { headerName: "Country", field: "country_code", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true },
      { headerName: "Line", field: "line", sortable: true, filter: true, width: 120, pinned: 'left', enableRowGroup: true }, 
    ];
  
    sortedMonths.forEach(month => {
      const children = [
        {headerName: `Orders`, field: `quantity_ordered_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 110, 
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
        {headerName: `Returns`, field: `quantity_returned_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US') : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
        {headerName: `Return Ratio`, field: `returned_ratio_${month}`, sortable: true, filter: 'agNumberColumnFilter', width: 120, 
          valueFormatter: params => params.value ? `${params.value.toLocaleString('en-US')} %` : '', cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
      ];
  
      columns.push({
        headerName: `${month}`,
        children: children,
      });
    });
  
    return columns;
  };
