import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { cospColumns, columnSets } from './cospColumns.js';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import AGGrid_Options from '../../components/global/AGGrid_Options.jsx';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar.jsx';

const COSP = () => {
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const gridRef = useRef(null);
  const [selectedAlert, setSelectedAlert] = useState('');
  const [quickFilterText, setQuickFilterText] = useState('');
  const [columnDefs, setColumnDefs] = useState(columnSets.default);
  const [selectedView, setSelectedView] = useState('default');

  function multiFieldSort(data) {
    return data.sort((a, b) => {
      if (a.country_code < b.country_code) return -1;
      if (a.country_code > b.country_code) return 1;
      if (a.Line < b.Line) return -1;
      if (a.Line > b.Line) return 1;
      if (a.cat < b.cat) return -1;
      if (a.cat > b.cat) return 1;
      return 0;
    });
  }
  
  useEffect(() => {
    document.title = "SKU Details | Encasa HQ";
    console.log('Initiating COSP data fetch');
    fetch('/api/cosp/get_cosp')
      .then(response => {
        console.log(`Response status: ${response.status}`);
        console.log('Response headers:', response.headers);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          const sortedData = multiFieldSort(data);
          setRowData(sortedData);
        } else if (data && data.error) {
          console.error('Error in data:', data.error);
          throw new Error(data.error);
        } else {
          console.error('Unexpected data structure:', typeof data);
          throw new Error('Received data is not an array');
        }
      })
      .catch(error => {
        console.error('Error fetching COSP data:', error);
        console.error('Error stack:', error.stack);
        setError(error.message);
        setRowData([]); // In case of error, clear the row data
      });
  }, []);

  const handleExportCSV = () => {
    console.log('Exporting CSV...'); // Log when export starts
    gridRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        if (params.column.getColId() === 'alerts' && params.value) {
          if (Array.isArray(params.value)) {
            return params.value.map(alert => 
              typeof alert === 'object' && alert !== null ? alert.message : alert
            ).join(', ');
          }
          if (typeof params.value === 'object' && params.value !== null) {
            return params.value.message;
          }
        }
        return params.value;
      }
    });
  };

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setQuickFilterText('');
    setSelectedAlert('');
    // Force the QuickFilter component to reset
    const quickFilterInput = document.querySelector('input[placeholder="Quick Filter... (Press Enter to apply)"]');
    if (quickFilterInput) {
      quickFilterInput.value = '';
    }
  }, []);

  const handleQuickFilterChange = useCallback((filterText) => {
    setQuickFilterText(filterText);
  }, []);

  const alertOptions = ['High DOC', 'Low Stock', 'Low CVR', 'LTSF', 'High ACOS', 'High Returns', 'No Sales 15d'];

  const handleAlertChange = (event) => {
    setSelectedAlert(event.target.value);
    if (event.target.value) {
      // Get the existing filter model
      let existingFilterModel = gridRef.current.api.getFilterModel();

      // Define the new filter model for the selected alert
      let newFilterModel = {
        alerts: {
          filterType: 'set',
          values: [event.target.value]
        }
      };

      // Additional filter for "No Sales 15d"
      if (event.target.value === 'No Sales 15d') {
        newFilterModel.total_stock = {
          filterType: 'number',
          type: 'notEqual',
          filter: 0
        };
      }

      // Merge the new filter model with the existing filter model
      let mergedFilterModel = { ...existingFilterModel, ...newFilterModel };

      // Apply the merged filter model
      gridRef.current.api.setFilterModel(mergedFilterModel);

      // Define sorting based on the selected alert
      let sortModel = [];
      switch (event.target.value) {
        case 'High DOC':
          sortModel = [{ colId: 'doc', sort: 'desc' }];
          break;
        case 'Low Stock':
          sortModel = [{ colId: 'ma', sort: 'desc' }];
          break;
        case 'Low CVR':
          sortModel = [{ colId: 'cvr', sort: 'asc' }];
          break;
        case 'LTSF':
          sortModel = [{ colId: 'fba_stock', sort: 'desc' }];
          break;
        case 'High ACOS':
          sortModel = [{ colId: 'acos', sort: 'desc' }];
          break;
        case 'High Returns':
          sortModel = [{ colId: 'L1_ReturnRatio', sort: 'desc' }];
          break;
        case 'No Sales 15d':
          sortModel = [{ colId: 'last_sold_days', sort: 'desc' }];
          break;
        default:
          break;
      }

      // Apply the sort model after the filter model
      setTimeout(() => {
        gridRef.current.api.applyColumnState({
          state: sortModel,
          defaultState: { sort: null },
        });
      }, 0);
    } else {
      gridRef.current.api.setFilterModel(null);
      gridRef.current.api.applyColumnState({
        defaultState: { sort: null },
      }); // Clear sorting when no alert is selected
    }
  };

  const handleViewChange = (event) => {
    const viewType = event.target.value;
    console.log('Changing view to:', viewType);
    console.log('Current columns:', columnSets[viewType]);
    setSelectedView(viewType);
    setColumnDefs(columnSets[viewType]);
  };

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">SKU Details</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
            <InputLabel>Select View</InputLabel>
            <Select
              value={selectedView}
              onChange={handleViewChange}
              label="Select View"
            >
              <MenuItem value="default">Default View</MenuItem>
              <MenuItem value="fulfilmentFee">Fulfilment Fee View</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
            <InputLabel>Filter by Alert</InputLabel>
            <Select
              value={selectedAlert}
              onChange={handleAlertChange}
              label="Filter by Alert"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {alertOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters}
            size="small"
          >
            Clear Filters
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV}
            size="small"
          >
            Export CSV
          </Button>
        </Box>
      </Box>
      <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          {...AGGrid_Options}
          sideBar={AGGrid_Sidebar}
          quickFilterText={quickFilterText}
        />
      </div>
    </div>
  );
};

export default COSP;