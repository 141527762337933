import React, { useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import AGGrid_Options from '../../components/global/AGGrid_Options';
import AGGrid_Sidebar from '../../components/global/AGGrid_Sidebar';

const LineFulfilmentFee = () => {
  const [line, setLine] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [rowData, setRowData] = useState([]);
  const [fetchFeedback, setFetchFeedback] = useState({ message: '', style: {} });
  const gridRef = useRef(null);

  const columnDefs = [
    { field: 'seller_sku', headerName: 'Seller SKU', width: 200, filter: true },
    { field: 'asin', headerName: 'ASIN', width: 150, filter: true },
    { field: 'currency', headerName: 'Currency', width: 125, filter: true },
    { field: 'line', headerName: 'Line', width: 100, filter: true },
    { field: 'size', headerName: 'Size', width: 100, filter: true },
    { field: 'Color_Design', headerName: 'Color', width: 100, filter: true },
    { field: 'pcs_pack', headerName: 'Pcs/Pack', width: 120, cellStyle: { textAlign: 'right' }, filter: true },
    { 
      field: 'mode_fee', 
      headerName: 'Mode Fee', 
      width: 120, 
      cellStyle: { textAlign: 'right' }, 
      filter: true,
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
    { 
      field: 'ma', 
      headerName: 'MA', 
      width: 100, 
      cellStyle: { textAlign: 'right' }, 
      filter: true,
      valueFormatter: (params) => params.value ? params.value.toFixed(2) : '0.00'
    },
    {
      field: 'monthly_fee',
      headerName: 'Monthly Fee',
      width: 150,
      cellStyle: { textAlign: 'right' },
      type: 'numericColumn',
      valueGetter: (params) => {
        const modeFee = params.data.mode_fee || 0;
        const ma = params.data.ma || 0;
        return modeFee * ma * 30;
      },
      valueFormatter: (params) => params.value.toFixed(2)
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      cellRenderer: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRowButtonClick(params.data)}
        >
          Go to SKU
        </Button>
      )
    },
  ];

  const handleFetchData = useCallback(() => {
    setFetchFeedback({ message: 'Fetching data...', style: { color: 'blue' } });
    fetch('/api/fulfilment_fee/line', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ line: line.trim(), currency: currency }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setRowData(data);
          setFetchFeedback({ message: 'Data fetched successfully', style: { color: 'green' } });
        } else {
          setFetchFeedback({ message: 'Unexpected response format', style: { color: 'orange' } });
        }
      })
      .catch((error) => {
        setFetchFeedback({ message: 'Error fetching data', style: { color: 'red' } });
      });
  }, [line, currency]);

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleRowButtonClick = useCallback((rowData) => {
    window.open(`/fulfilment_fee/sku?sku=${rowData.seller_sku}&currency=${rowData.currency}`, '_blank');
  }, []);
  
  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Line Fulfilment Fee</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleExportCSV}
          >
            Export to CSV
          </Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} height="calc(100vh - 100px)">
        {/* Left Panel - width reduced to 200px */}
        <Box display="flex" flexDirection="column" gap={1} width="200px">
          <FormControl variant="outlined" sx={{ width: '200px', mb: 1 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Currency"
            >
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="GBP">GBP</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              <MenuItem value="INR">INR</MenuItem>
              <MenuItem value="JPY">JPY</MenuItem>
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="MXN">MXN</MenuItem>
            </Select>
          </FormControl>
          
          <TextField
            label="Line"
            variant="outlined"
            value={line}
            onChange={(e) => setLine(e.target.value)}
            sx={{ width: '200px', mb: 1 }}
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            sx={{ width: '200px' }}
          >
            Fetch Data
          </Button>
          
          <Typography variant="body2" sx={{ ...fetchFeedback.style, mt: 1 }}>
            {fetchFeedback.message}
          </Typography>
        </Box>

        {/* Right Panel */}
        <Box flex={1}>
          <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              {...AGGrid_Options}
              sideBar={AGGrid_Sidebar}
            />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default LineFulfilmentFee;