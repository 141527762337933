import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Box, Typography, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columnSets, gridOptions } from './monthlyReportColumns';
import QuickFilter from '../../components/global/QuickFilter.jsx';
import SummaryTable from './SummaryTable';

const MonthlyReport = () => {
  const [fullData, setFullData] = useState([]);
  const [rowData, setRowData] = useState({ biggestLines: [], topGrowth: [], topDegrowth: [], topProfit: [], topLoss: [] });
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState({
    units: null,
    unitsMoM: null,
    unitsYoY: null,
    sales: null,
    salesMoM: null,
    salesYoY: null,
    profit: null,
    profitMoM: null,
    profitYoY: null
  });
  
  // Declare all refs at the top level
  const biggestLinesGridRef = useRef(null);
  const topGrowthGridRef = useRef(null);
  const topDegrowthGridRef = useRef(null);
  const topProfitGridRef = useRef(null);
  const topLossGridRef = useRef(null);
  const lineSummaryGridRef = useRef(null);
  const countrySummaryGridRef = useRef(null);
  
  const [selectedDRI, setSelectedDRI] = useState('');
  const [driList, setDriList] = useState([]);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
const calculateSummary = useCallback((data) => {
  if (!data || data.length === 0) return;

  // Calculate sums for current period
  const currentUnits = data.reduce((sum, row) => sum + (row._l1 || 0), 0);
  const currentSales = data.reduce((sum, row) => sum + (row.sales_sb || 0), 0);
  const currentProfit = data.reduce((sum, row) => sum + (row.npm_month_2 || 0), 0);

  // Calculate MoM changes
  const lastMonthUnits = data.reduce((sum, row) => sum + (row._l2 || 0), 0);
  const lastMonthSales = data.reduce((sum, row) => sum + (row.sales_sb_2 || 0), 0);
  const lastMonthProfit = data.reduce((sum, row) => sum + (row.npm_month_3 || 0), 0);

  const unitsMoM = lastMonthUnits ? (currentUnits - lastMonthUnits) / lastMonthUnits : null;
  const salesMoM = lastMonthSales ? (currentSales - lastMonthSales) / lastMonthSales : null;
  const profitMoM = lastMonthProfit ? (currentProfit - lastMonthProfit) / lastMonthProfit : null;

  // Calculate YoY changes
  const lastYearUnits = data.reduce((sum, row) => sum + (row._l13 || 0), 0);
  const lastYearSales = data.reduce((sum, row) => sum + (row.sales_sb_13 || 0), 0);

  const unitsYoY = lastYearUnits ? (currentUnits - lastYearUnits) / lastYearUnits : null;
  const salesYoY = lastYearSales ? (currentSales - lastYearSales) / lastYearSales : null;

  // Add console.log to debug the values
  console.log('Summary Data:', {
    currentUnits,
    lastMonthUnits,
    lastYearUnits,
    currentProfit,
    lastMonthProfit,
    lastYearSales 
  });

  setSummaryData({
    units: currentUnits,
    unitsMoM,
    unitsYoY,
    profit: currentProfit,
    profitMoM,
    sales: currentSales,
    salesMoM,
    salesYoY
  });
}, []);

  const processData = useCallback((data, selectedDRI = '') => {
    let filteredData = [...data];
    
    // Apply DRI filter if selected
    if (selectedDRI) {
      filteredData = filteredData.filter(row => row.dri === selectedDRI);
    }

    // Calculate summary for filtered data
    calculateSummary(filteredData);

    // Filter out rows with blank or 0 mom_delta for degrowth
    const validDegrowthData = filteredData.filter(row => 
      row.mom_delta !== null && row.mom_delta !== 0
    );

    // Filter out rows with blank or 0 net_profit_sb for loss
    const validProfitData = filteredData.filter(row => 
      row.net_profit_sb !== null && row.net_profit_sb !== 0
    );

    setRowData({
      biggestLines: filteredData,
      topGrowth: filteredData,
      topDegrowth: validDegrowthData,
      topProfit: validProfitData,
      topLoss: validProfitData
    });
  }, [calculateSummary]);

  useEffect(() => {
    document.title = "Monthly Report | Encasa HQ";
    console.log('Initiating Monthly Report data fetch');
    fetch('/api/line-details/get-details')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          const processedData = data.map(row => {
            const processedRow = {};
            for (const [key, value] of Object.entries(row)) {
              processedRow[key] = value ?? null;
            }
            return processedRow;
          });

          setFullData(processedData);
          processData(processedData);

          // Extract unique DRI values
          const uniqueDRIs = [...new Set(processedData.map(row => row.dri))].filter(Boolean).sort();
          setDriList(uniqueDRIs);
        } else {
          throw new Error('Received data is not an array or is empty');
        }
      })
      .catch(error => {
        console.error('Error fetching Monthly Report data:', error);
        setError(error.message);
        setRowData({ biggestLines: [], topGrowth: [], topDegrowth: [], topProfit: [], topLoss: [] });
      });
  }, [processData]);

  const handleExportCSV = useCallback((gridRef) => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  // Helper function to get the appropriate icon for each grid title
  const getTitleIcon = (gridType) => {
    switch(gridType) {
      case 'biggestLines':
        return <ShowChartIcon sx={{ ml: 1, verticalAlign: 'middle' }} />;
      case 'topGrowth':
        return <TrendingUpIcon sx={{ ml: 1, verticalAlign: 'middle', color: 'success.main' }} />;
      case 'topDegrowth':
        return <TrendingDownIcon sx={{ ml: 1, verticalAlign: 'middle', color: 'error.main' }} />;
      case 'topProfit':
        return <MonetizationOnIcon sx={{ ml: 1, verticalAlign: 'middle', color: 'success.main' }} />;
      case 'topLoss':
        return <MoneyOffIcon sx={{ ml: 1, verticalAlign: 'middle', color: 'error.main' }} />;
      case 'lineSummary':
      case 'countrySummary':
        return <AssessmentIcon sx={{ ml: 1, verticalAlign: 'middle' }} />;
      default:
        return null;
    }
  };

  // Helper function to render each table
  const renderGrid = (title, data, columns, gridRef, gridType) => (
    <Box mb={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {title}
            {getTitleIcon(gridType)}
          </Typography>
        </Box>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => handleExportCSV(gridRef)}
          size="small"
        >
          Export CSV
        </Button>
      </Box>
      <div className="ag-theme-alpine" style={{ height: '300px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columns}
          {...gridOptions[gridType]}
        />
      </div>
    </Box>
  );

  // Helper function to handle DRI change from dropdown
  const handleDRIChange = (event) => {
    const newValue = event.target.value;
    setSelectedDRI(newValue);
    processData(fullData, newValue);
  };

  // Main render function
  return (
    <div style={{ padding: '20px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Monthly Report</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
            <InputLabel>Filter by DRI</InputLabel>
            <Select
              value={selectedDRI}
              onChange={handleDRIChange}
              label="Filter by DRI"
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {driList.map((dri) => (
                <MenuItem key={dri} value={dri}>{dri}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <QuickFilter onFilterChange={setAppliedQuickFilter} />
        </Box>
      </Box>

      {error ? (
        <Typography color="error" variant="h6">Error: {error}</Typography>
      ) : (
        <>
          <SummaryTable data={summaryData} />
          {renderGrid("Biggest Lines", rowData.biggestLines, columnSets.biggestLines, biggestLinesGridRef, 'biggestLines')}
          {renderGrid("Top Growth", rowData.topGrowth, columnSets.topGrowth, topGrowthGridRef, 'topGrowth')}
          {renderGrid("Top Degrowth", rowData.topDegrowth, columnSets.topDegrowth, topDegrowthGridRef, 'topDegrowth')}
          {renderGrid("Top Profit", rowData.topProfit, columnSets.topProfit, topProfitGridRef, 'topProfit')}
          {renderGrid("Top Loss", rowData.topLoss, columnSets.topLoss, topLossGridRef, 'topLoss')}
          {renderGrid("Line Summary", rowData.biggestLines, columnSets.lineSummary, lineSummaryGridRef, 'lineSummary')}
          {renderGrid("Country Summary", rowData.biggestLines, columnSets.countrySummary, countrySummaryGridRef, 'countrySummary')}
        </>
      )}
    </div>
  );
};

export default MonthlyReport; 