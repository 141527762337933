import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard/dashboard";
import { CatalogLinesList, CatalogLinesForm } from "./scenes/catalog_lines/catalog_lines";
import { CatalogSizeVariationsList, CatalogSizeVariationsForm } from "./scenes/catalog_size_variations/catalog_size_variations";
import CatalogTools from "./scenes/catalog_tools/catalog_tools";
import Variations from "./scenes/variations/variations";
import { SkuFulfilmentFee, LineFulfilmentFee } from "./scenes/fulfilment_fee/fulfilment_fee";
import Logs from "./scenes/logs/logs";
import Returns from "./scenes/returns/returns";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Login from './components/auth/Login';
import PrivateRoute from './components/auth/PrivateRoute';
import SBDashboard from "./scenes/sb_dashboard/sb_dashboard";
import COSP from "./scenes/cosp/cosp";
import LineDaily from "./scenes/line_daily/line_daily";
import LineMonthly from "./scenes/line_monthly/line_monthly";
import DAM from "./scenes/dam/dam";
import CacheClearingPanel from "./scenes/cache_clearing_panel/cache_clearing_panel";
import KeywordExplorer from "./scenes/keyword_explorer/keyword_explorer";
import LineDetails from "./scenes/line_details/line_details";
import SP from "./scenes/sp/sp";
import CampaignChanges from "./scenes/campaign_changes/campaign_changes";
import ReturnsAnalysis from "./scenes/returns/returns_analysis";
import ProductLines from "./scenes/product_lines/product_lines";
import CompetitorSearch from "./scenes/competitor_search/competitor_search";
import IndexChecker from "./scenes/index_checker/index_checker";
import Repricing from "./scenes/repricing/repricing";
import DAMFolder from "./scenes/dam_folder/dam_folder";
import MonthlyReport from "./scenes/monthly_report/monthly_report";
import Pricing_Fee_Preview from "./scenes/fulfilment_fee/pricing_fee_preview";
import KeywordHistoric from "./scenes/keyword_historic/keyword_historic";
import ScratchpadFeed from "./scenes/scratchpad_feed/scratchpad_feed";

function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));

  useEffect(() => {
    const resizeObserverError = error => {
      if (error.message.includes('ResizeObserver loop')) {
        const resizeObserverError = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverError) {
          resizeObserverError.style.display = 'none'
        }
      }
    }

    window.addEventListener('error', resizeObserverError)

    return () => window.removeEventListener('error', resizeObserverError)
  }, [])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {isAuthenticated && <Sidebar />}
          <main className="content">
            <Routes>
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route element={<PrivateRoute isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/catalog_lines/list" element={<CatalogLinesList />} />
                <Route path="/catalog_lines/form" element={<CatalogLinesForm />} />
                <Route path="/catalog_size_variations/list" element={<CatalogSizeVariationsList />} />
                <Route path="/catalog_size_variations/form" element={<CatalogSizeVariationsForm />} />
                <Route path="/catalog_tools/list" element={<CatalogTools />} />
                <Route path="/variations/list" element={<Variations />} />
                <Route path="/fulfilment_fee/sku" element={<SkuFulfilmentFee />} />
                <Route path="/fulfilment_fee/line" element={<LineFulfilmentFee />} />
                <Route path="/fulfilment_fee/pricing_fee_preview" element={<Pricing_Fee_Preview />} />
                <Route path="/logs/list" element={<Logs />} />
                <Route path="/Returns" element={<Returns />} />
                <Route path="/sb_dashboard" element={<SBDashboard />} />
                <Route path="/cosp" element={<COSP />} />
                <Route path="/line_daily" element={<LineDaily />} />
                <Route path="/line_monthly" element={<LineMonthly />} />
                <Route path="/dam" element={<DAM />} />
                <Route path="/cache-clearing" element={<CacheClearingPanel />} />
                <Route path="/keyword_explorer" element={<KeywordExplorer />} />
                <Route path="/line_details" element={<LineDetails />} />
                <Route path="/sp" element={<SP />} />
                <Route path="/campaign_changes" element={<CampaignChanges />} />
                <Route path="/Returns_Analysis" element={<ReturnsAnalysis />} />
                <Route path="/product_lines" element={<ProductLines />} />
                <Route path="/competitor_search" element={<CompetitorSearch />} />
                <Route path="/index_checker" element={<IndexChecker />} />
                <Route path="/repricing" element={<Repricing />} />
                <Route path="/dam_folder" element={<DAMFolder />} />
                <Route path="/monthly-report" element={<MonthlyReport />} />
                <Route path="/keyword_historic" element={<KeywordHistoric />} />
                <Route path="/scratchpad-feed" element={<ScratchpadFeed />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
