import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const ScratchpadNotes = ({ line, country, sku }) => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const fetchNotes = async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({ line });
      if (country) queryParams.append('country', country);
      if (sku) queryParams.append('sku', sku);
      
      const response = await fetch(`/api/scratchpad/notes?${queryParams}`);
      if (!response.ok) throw new Error('Failed to fetch notes');
      
      const data = await response.json();
      setNotes(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchNotes();
  };

  const handleClose = () => {
    setOpen(false);
    setNewNote('');
  };

  const handleSubmit = async () => {
    if (!newNote.trim()) return;

    try {
        setError(null);
        setSuccessMessage('');
        setLoading(true);

        const response = await fetch('/api/scratchpad/notes', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                line,
                country,
                sku,
                content: newNote.trim()
            })
        });

        if (!response.ok) throw new Error('Failed to create note');

        setNewNote('');
        setSuccessMessage('Note added successfully');
        
        await new Promise(resolve => setTimeout(resolve, 1000));
        await fetchNotes();
        
        setTimeout(() => setSuccessMessage(''), 3000);
        
    } catch (err) {
        setError(err.message);
    } finally {
        setLoading(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="small">
        <EditNoteIcon />
      </IconButton>

      <Dialog 
        open={open} 
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Notes for {line} {country ? `(${country})` : ''} {sku ? `- ${sku}` : ''}
        </DialogTitle>
        
        <DialogContent>
          {error && (
            <Typography color="error" gutterBottom>
              Error: {error}
            </Typography>
          )}
          
          {successMessage && (
            <Typography color="success.main" gutterBottom>
              {successMessage}
            </Typography>
          )}

          <Box mb={3}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Add a new note..."
              value={newNote}
              onChange={(e) => setNewNote(e.target.value)}
              margin="normal"
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleSubmit}
              disabled={!newNote.trim() || loading}
            >
              {loading ? 'Adding...' : 'Add Note'}
            </Button>
          </Box>

          <Divider />

          {loading ? (
            <Typography>Loading notes...</Typography>
          ) : notes.length > 0 ? (
            <List>
              {notes.map((note) => (
                <ListItem 
                  key={`${note.id}-${note.version_number}`} 
                  divider
                  secondaryAction={
                    <Typography variant="caption" color="textSecondary">
                      {formatDateTime(note.updated_at)}
                    </Typography>
                  }
                >
                  <ListItemText primary={note.content} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography color="textSecondary" align="center" sx={{ my: 2 }}>
              No notes found
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScratchpadNotes; 