import { Box, Button, TextField, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
// import AGGrid_Options from "../../components/global/AGGrid_Options";

const CompetitorSearch = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const gridRef = useRef(null);

  const [country, setCountry] = useState("");
  const [keywords, setKeywords] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calculations, setCalculations] = useState({
    total: 0,
    estimatedTime: 0
  });
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [includeKeywords, setIncludeKeywords] = useState("");
  const [includeAndKeywords, setIncludeAndKeywords] = useState("");
  const [excludeKeywords, setExcludeKeywords] = useState("");
  const [isGridReady, setIsGridReady] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [countryError, setCountryError] = useState(false);
  const [keywordsError, setKeywordsError] = useState(false);

  useEffect(() => {
    const keywordCount = keywords.split('\n').filter(k => k.trim()).length;
    const estimatedTimeSeconds = keywordCount * 2; // 2 seconds per keyword

    setCalculations({
      total: keywordCount,
      estimatedTime: estimatedTimeSeconds
    });
  }, [keywords]);

  const countries = [
    { value: "US", label: "US", domain: "com" },
    { value: "UK", label: "UK", domain: "co.uk" },
    { value: "CA", label: "CA", domain: "ca" },
    { value: "IN", label: "IN", domain: "in" },
    { value: "DE", label: "DE", domain: "de" },
    { value: "FR", label: "FR", domain: "fr" },
    { value: "IT", label: "IT", domain: "it" },
    { value: "ES", label: "ES", domain: "es" },
    { value: "JP", label: "JP", domain: "co.jp" },
  ];

  const AGGrid_Options = {
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    enableRangeSelection: true,
    multiSortKey: "ctrl",
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 500, 1000],
    // paginationNumberFormatter: (params) => {
    //   return '[' + params.value.toLocaleString() + ']';
    // },
    rowHeight: 25,
    headerHeight: 35,
    suppressHorizontalScroll: false,
    // animateRows: true,
    rowSelection: "multiple",
    // cacheQuickFilter: true,
    // grandTotalRow: "bottom"
  };

  const columns = [
    { 
      field: "asin", headerName: "ASIN", width: 130, cellStyle: { fontSize: '14px' },
      cellRenderer: params => {
        const selectedCountry = countries.find(c => c.value === country);
        if (!selectedCountry) return params.value;
        
        const url = `https://www.amazon.${selectedCountry.domain}/dp/${params.value}`;
        return (
          <a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#2196f3', textDecoration: 'none' }}
            onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
            onMouseOut={(e) => e.target.style.textDecoration = 'none'}
          >
            {params.value}
          </a>
        );
      }
    },
    { field: "title", headerName: "Title", flex: 1, minWidth: 200, tooltipField: 'title', cellStyle: { fontSize: '14px' } },
    { field: "count", headerName: "#", width: 100, cellStyle: { fontSize: '14px' } },
  ];

  const startTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
    }
    
    setTimer(0);
    
    const interval = setInterval(() => {
      setTimer(prev => prev + 1);
    }, 1000);
    
    setTimerInterval(interval);
  };

  const handleSearch = async () => {
    setCountryError(!country);
    setKeywordsError(!keywords.trim());

    if (!country || !keywords.trim()) {
      return;
    }

    setLoading(true);
    startTimer();
    
    try {
      const response = await fetch("/api/search/competitor-search", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country, keywords }),
      });
      const data = await response.json();
      setResults(data);
      setFilteredResults(data);
      setIncludeKeywords("");
      setIncludeAndKeywords("");
      setExcludeKeywords("");
    } catch (error) {
      console.error("Error:", error);
    }
    
    setLoading(false);
    clearInterval(timerInterval);
    setTimerInterval(null);
  };

  const handleExportCSV = useCallback(() => {
    gridRef.current?.api.exportDataAsCsv();
  }, []);

  const handleOpenInAmazon = () => {
    if (!country || results.length === 0 || !gridRef.current) return;
    
    const selectedCountry = countries.find(c => c.value === country);
    if (!selectedCountry) return;

    // Get the current view of data from the grid (respecting filters and sorting)
    const currentGridData = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node, index) => {
      if (index < 100) {  // Only take first 100 rows
        currentGridData.push(node.data);
      }
    });

    const asins = currentGridData.map(row => row.asin).join('|');
    const url = `https://www.amazon.${selectedCountry.domain}/s?k=${asins}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [timerInterval]);

  const onGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  const handleFilterChange = useCallback(() => {
    const includeList = includeKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);
    
    const includeAndList = includeAndKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);
    
    const excludeList = excludeKeywords
      .split(',')
      .map(k => k.trim().toLowerCase())
      .filter(k => k.length > 0);

    let filtered = [...results];

    if (includeList.length > 0) {
      filtered = filtered.filter(row => 
        includeList.some(keyword => 
          row.title.toLowerCase().includes(keyword)
        )
      );
    }

    if (includeAndList.length > 0) {
      filtered = filtered.filter(row => 
        includeAndList.every(keyword => 
          row.title.toLowerCase().includes(keyword)
        )
      );
    }

    if (excludeList.length > 0) {
      filtered = filtered.filter(row => 
        !excludeList.some(keyword => 
          row.title.toLowerCase().includes(keyword)
        )
      );
    }

    setFilteredResults(filtered);
  }, [includeKeywords, includeAndKeywords, excludeKeywords, results]);

  useEffect(() => {
      handleFilterChange();
  }, [includeKeywords, includeAndKeywords, excludeKeywords, handleFilterChange]);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* Header with title and action buttons */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Competitor Search</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            variant="contained"
            onClick={handleOpenInAmazon}
            disabled={!country || results.length === 0}
          >
            OPEN IN AMAZON
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleExportCSV}
          >
            Export CSV
          </Button>
        </Box>
      </Box>

      {/* Main content container with minimal gap */}
      <Box display="flex" gap={2} height="calc(100vh - 80px)">
        {/* Left side - All inputs */}
        <Box display="flex" flexDirection="column" gap={1} width="250px">
          <TextField
            select
            label="Country"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
              setCountryError(false);
            }}
            sx={{ width: "250px" }}
            error={countryError}
            helperText={countryError ? "Country is required" : ""}
          >
            {countries.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Keywords (one per line)"
            multiline
            rows={10}
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
              setKeywordsError(false);
            }}
            sx={{ width: "250px" }}
            error={keywordsError}
            helperText={keywordsError ? "At least one keyword is required" : ""}
          />

          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Include keywords (OR) (comma separated)"
              value={includeKeywords}
              onChange={(e) => setIncludeKeywords(e.target.value)}
              sx={{ width: "250px" }}
            />
            <TextField
              label="Include keywords (AND) (comma separated)"
              value={includeAndKeywords}
              onChange={(e) => setIncludeAndKeywords(e.target.value)}
              sx={{ width: "250px" }}
            />
            <TextField
              label="Exclude keywords (comma separated)"
              value={excludeKeywords}
              onChange={(e) => setExcludeKeywords(e.target.value)}
              sx={{ width: "250px" }}
            />
          </Box>

          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={loading}
            sx={{ width: "250px" }}
          >
            {loading ? "Searching..." : "Search"}
          </Button>

          {/* Calculations display */}
          {calculations.total > 0 && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography 
                variant="body2" 
                sx={{ 
                  textAlign: 'left',
                  color: 'text.primary',
                  whiteSpace: 'nowrap'
                }}
              >
                No. of Keywords: {calculations.total}
                <br />
                Estimated Time: ~{Math.floor(calculations.estimatedTime / 60)} min {Math.floor(calculations.estimatedTime % 60)} secs
              </Typography>
              {timer > 0 && (
                <Typography 
                  variant="body2" 
                  sx={{ 
                    textAlign: 'left',
                    color: 'text.primary',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Elapsed Time: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
                  {loading && (  // Only show warning message while loading is true
                    <>
                      <br />
                      <span style={{ color: 'brown' }}>Do not navigate away while this is running</span>
                    </>
                  )}
                </Typography>
              )}
            </Box>
          )}
        </Box>

        {/* Right side - Grid */}
        <div className="ag-theme-alpine" style={{ flex: 1 }}>
          <AgGridReact
            ref={gridRef}
            rowData={filteredResults}
            columnDefs={columns}
            {...AGGrid_Options}
            sideBar={AGGrid_Sidebar}
            onGridReady={onGridReady}
          />
        </div>
      </Box>
    </div>
  );
};

export default CompetitorSearch;