import { useState, useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { ColorModeContext, tokens } from "../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BarChartIcon from '@mui/icons-material/BarChart';
import CategoryIcon from '@mui/icons-material/Category';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HistoryIcon from '@mui/icons-material/History';
import ImageIcon from '@mui/icons-material/Image';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import InventoryIcon from "@mui/icons-material/Inventory";
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from '@mui/icons-material/Cached';
import { SvgIcon } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import LineDetailsIcon from '@mui/icons-material/LineStyle';
import CampaignIcon from '@mui/icons-material/Campaign';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FolderIcon from '@mui/icons-material/Folder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FeedIcon from '@mui/icons-material/Feed';

const TrendingUpDay1Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="20" y="22" fontSize="12" textAnchor="end" fill="currentColor">1</text>
  </SvgIcon>
);

const TrendingUpDay30Icon = (props) => (
  <SvgIcon {...props}>
    <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
    <text x="22" y="22" fontSize="12" textAnchor="end" fill="currentColor">30</text>
  </SvgIcon>
);

const Item = ({ title, to, icon, selected, setSelected, setIsCollapsed, isCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const menuItem = (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => { setSelected(title); if (typeof setIsCollapsed === 'function') { setIsCollapsed(true); } }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );

  return isCollapsed ? (
    <Tooltip title={title} placement="right">
      {menuItem}
    </Tooltip>
  ) : menuItem;
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [showIcons, setShowIcons] = useState(false);
  const [isCatalogingExpanded, setIsCatalogingExpanded] = useState(false);
  const [isExperimentalExpanded, setIsExperimentalExpanded] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
  };

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  const toggleCataloging = () => {
    setIsCatalogingExpanded(!isCatalogingExpanded);
  };

  const toggleExperimental = () => {
    setIsExperimentalExpanded(!isExperimentalExpanded);
  };

  return (
    <Box sx={{
      "& .pro-sidebar-inner": { 
        background: `${colors.primary[400]} !important`,
        width: isCollapsed ? "60px" : "230px"
      },
      "& .pro-sidebar": {
        width: isCollapsed ? "60px !important" : "240px !important",
        minWidth: isCollapsed ? "60px !important" : "240px !important"
      },
      "& .pro-icon-wrapper": { backgroundColor: "transparent !important" },
      "& .pro-inner-item": { 
        padding: isCollapsed ? "2px 5px 3px 10px !important" : "2px 15px 3px 10px !important" 
      },
      "& .pro-inner-item:hover": { color: "#868dfb !important" },
      "& .pro-menu-item.active": { color: "#6870fa !important" },
      "& .settings-row": { display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "10px", position: "absolute", bottom: 0, left: 2, right: 0 },
    }}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem onClick={() => setIsCollapsed(!isCollapsed)} icon={isCollapsed ? <MenuOutlinedIcon /> : undefined} style={{ margin: isCollapsed ? "0" : "10px 0 20px 15px", color: colors.grey[100] }}>
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="1px">
                <Typography variant="h3" color={colors.grey[100]}>Encasa HQ</Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}><MenuOutlinedIcon /></IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Cataloging */}
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Box sx={{ display: 'flex', alignItems: 'center', m: "15px 0 5px 5px", cursor: 'pointer' }} onClick={toggleCataloging}>
              <Typography variant="h6" fontWeight="bold" color={colors.grey[300]} sx={{ fontSize: isCollapsed ? '11px' : '15px', transition: 'font-size 0.3s ease' }}>Cataloging</Typography>
              {!isCollapsed && (<Box sx={{ display: 'inline-flex' }}><Box sx={{ ml: 13.5, color: '#808080' }}>{isCatalogingExpanded ? '▼' : '►'}</Box></Box>)}
            </Box>
          
            {isCatalogingExpanded && (
              <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                <Item title="Lines Details" to="/catalog_lines/list" icon={<CategoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Size Variations" to="/catalog_size_variations/list" icon={<FormatSizeIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Grouping" to="/catalog_tools/list" icon={<GroupWorkIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Listing Logs" to="/logs/list" icon={<HistoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Digital Asset Management" to="/dam" icon={<ImageIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="DAM Folder" to="/dam_folder" icon={<FolderIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
              </Box>
            )}

            {/* Marketing */}
            <Typography variant="h6" fontWeight='bold' color={colors.grey[300]} sx={{ m: "15px 0 5px 5px", fontSize: isCollapsed ? '11px' : '15px', transition: 'font-size 0.3s ease' }}>Marketing</Typography>

            <Item title="SKU Details" to="/cosp" icon={<InventoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Line Details" to="/line_details" icon={<LineDetailsIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Line Historic" to="/line_monthly" icon={<TrendingUpDay30Icon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Campaign Changes" to="/campaign_changes" icon={<CampaignIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Returns Reasons" to="/returns" icon={<AssignmentReturnIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Returns Analysis" to="/Returns_Analysis" icon={<AssignmentReturnOutlinedIcon/>} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Keyword Historic" to="/keyword_historic" icon={<HistoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Product Lines" to="/product_lines" icon={<InventoryIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />    
            
            {/* Tools */}
            <Typography variant="h6" fontWeight='bold' color={colors.grey[300]} sx={{ m: "15px 0 5px 5px", fontSize: isCollapsed ? '11px' : '15px', transition: 'font-size 0.3s ease' }}>Tools</Typography>

            <Item title="Competitor Search" to="/competitor_search" icon={<SearchIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Index Checker" to="/index_checker" icon={<CheckCircleIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Variations" to="/variations/list" icon={<ShoppingCartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />

            {/* Operations */}
            <Typography variant="h6" fontWeight='bold' color={colors.grey[300]} sx={{ m: "15px 0 5px 5px", fontSize: isCollapsed ? '11px' : '15px', transition: 'font-size 0.3s ease' }}>Operations</Typography>

            <Item title="Sales Prediction" to="/sp" icon={<BarChartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="Line Fulfilment Fee" to="/fulfilment_fee/line" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="SKU Fulfilment Fee" to="/fulfilment_fee/sku" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
            <Item title="SB Dashboard" to="/sb_dashboard" icon={<BarChartIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />

            {/* Experimental */}
            <Box sx={{ display: 'flex', alignItems: 'center', m: "15px 0 5px 5px", cursor: 'pointer' }} onClick={toggleExperimental}>
              <Typography variant="h6" fontWeight="bold" color={colors.grey[300]} sx={{ fontSize: isCollapsed ? '11px' : '15px', transition: 'font-size 0.3s ease' }}>Experimental</Typography>
              {!isCollapsed && (<Box sx={{ display: 'inline-flex' }}><Box sx={{ ml: 11.2, color: '#808080' }}>{isExperimentalExpanded ? '▼' : '►'}</Box></Box>)}
            </Box>
          
            {isExperimentalExpanded && (
              <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                <Item title="Keyword Explorer" to="/keyword_explorer" icon={<ExploreIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Repricing" to="/repricing" icon={<AttachMoneyIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Monthly Report" to="/monthly-report" icon={<AssessmentOutlinedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Pricing" to="/fulfilment_fee/pricing_fee_preview" icon={<LocalShippingIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
                <Item title="Scratchpad Feed" to="/scratchpad-feed" icon={<FeedIcon />} selected={selected} setSelected={setSelected} setIsCollapsed={setIsCollapsed} isCollapsed={isCollapsed} />
              </Box>
            )}
          </Box>

          {/* Settings */}
          <Box className="settings-row" sx={{ flexDirection: 'column-reverse', alignItems:'flex-start', position: 'absolute', marginBottom: '25px', bottom: 20, left: 0 }}>
            {isCollapsed ? (
              <Tooltip title="Settings" placement="right">
                <IconButton onClick={toggleIcons} sx={{ color: colors.grey[100], marginBottom: '0px', left: 8, position: 'absolute', bottom: -8 }}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={toggleIcons} sx={{ color: colors.grey[100], marginBottom: '0px', left: 8, position: 'absolute', bottom: -8 }}>
                <SettingsOutlinedIcon />
              </IconButton>
            )}
            {showIcons && (
              <Box sx={{ display: 'flex', flexDirection: 'column-reverse', backgroundColor: colors.primary[400], padding: '8px', borderRadius: '4px', position: 'absolute', bottom: '100%', left: 0 }}>
                {isCollapsed ? (
                  <>
                    <Tooltip title="Toggle Theme" placement="right">
                      <IconButton onClick={colorMode.toggleColorMode} sx={{ color: colors.grey[100] }}>
                        {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout" placement="right">
                      <IconButton onClick={handleLogout} sx={{ color: colors.grey[100] }}><ExitToAppIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Clear Cache" placement="right">
                      <IconButton onClick={() => navigate('/cache-clearing')} sx={{ color: colors.grey[100] }}><CachedIcon /></IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <IconButton onClick={colorMode.toggleColorMode} sx={{ color: colors.grey[100] }}>
                      {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
                    </IconButton>
                    <IconButton onClick={handleLogout} sx={{ color: colors.grey[100] }}><ExitToAppIcon /></IconButton>
                    <IconButton onClick={() => navigate('/cache-clearing')} sx={{ color: colors.grey[100] }}><CachedIcon /></IconButton>
                  </>
                )}
              </Box>
            )}
          </Box>

        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
