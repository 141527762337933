import { Box, Button, TextField, MenuItem, Typography, Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material";
import { useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import AGGrid_Sidebar from "../../components/global/AGGrid_Sidebar";
import AGGrid_Options from "../../components/global/AGGrid_Options";

const OverallSummary = ({ nonIndexedWords }) => {
  const [aiResponse, setAiResponse] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const classifyWords = async () => {
      if (nonIndexedWords.length === 0) return; // Exit if no words to classify
      setLoading(true); // Show loading message

      // Query OpenAI
      try {
        const response = await fetch('/api/ai/query_openai', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            message: `Classify these keywords into the following categories:
- Product keywords
- Color or Design keywords 
- Size keywords

${nonIndexedWords.join(', ')}`
          })
        });
        
        // Handle response
        const data = await response.json();
        if (data.success) setAiResponse(data.response);

      } catch (error) {
        console.error('Error classifying words:', error);
      } finally {
        setLoading(false); // Hide loading message
      }
    };

    // Execute the function
    classifyWords();
  }, [nonIndexedWords]);

  return (
    <Box display="flex" flexDirection="column" gap={2} p={2}>
      <Box position="relative" width="250px">
        <TextField label="Non-Indexed Words" multiline rows={8} value={nonIndexedWords.join('\n')} InputProps={{ readOnly: true }} variant="outlined" sx={{ width: "100%" }} />
        <Box position="absolute" bottom={8} right={8} bgcolor="grey.300" color="black" borderRadius="50%" width={24} height={24} display="flex" alignItems="center" justifyContent="center" fontSize="0.75rem">{nonIndexedWords.length}</Box>
      </Box>
      {loading ? (
        <Typography>Classifying words...</Typography>
      ) : (
        <Box position="relative" width="500px">
          <TextField label="AI Classification" multiline rows={12} value={aiResponse} InputProps={{ readOnly: true }} variant="outlined" sx={{ width: "100%" }} />
        </Box>
      )}
    </Box>
  );
};

const IndexChecker = () => {
  const detailGridRef = useRef(null);
  const keywordPivotGridRef = useRef(null);
  const asinPivotGridRef = useRef(null);
  const [country, setCountry] = useState("");
  const [keywords, setKeywords] = useState("");
  const [asins, setAsins] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nonIndexedWords, setNonIndexedWords] = useState([]);
  const [calculations, setCalculations] = useState({ total: 0, estimatedTime: 0 });
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [viewMode, setViewMode] = useState('pivot');

  const countries = [
    { value: "US", label: "US" }, { value: "UK", label: "UK" }, { value: "CA", label: "CA" }, 
    { value: "IN", label: "IN" }, { value: "DE", label: "DE" }, { value: "FR", label: "FR" }, 
    { value: "IT", label: "IT" }, { value: "ES", label: "ES" }, { value: "JP", label: "JP" }
  ];

  const columns = [
    { field: "country", headerName: "Country", width: 130, cellStyle: { fontSize: '14px' } },
    { field: "asin", headerName: "ASIN", maxWidth: 120, cellStyle: { fontSize: '14px' } },
    { field: "keyword", headerName: "Keyword", flex: 2, minWidth: 300, cellStyle: { fontSize: '14px' } },
    { field: "indexed", headerName: "Indexed", maxWidth: 150, cellStyle: params => ({ fontSize: '14px', color: params.value === 'Y' ? '#4caf50' : '#f44336', fontWeight: 'bold' }) }
  ];

  const pivotColumns = [
    { field: "keyword", headerName: "Keyword", flex: 3, minWidth: 300, cellStyle: { fontSize: '14px' } },
    { field: "Y", headerName: "Indexed", maxWidth: 150, cellStyle: { fontSize: '14px' } },
    { field: "N", headerName: "Not Indexed", maxWidth: 150, cellStyle: { fontSize: '14px' } }
  ];

  const asinPivotColumns = [
    { field: "asin", headerName: "ASIN", flex: 1, minWidth: 300, cellStyle: { fontSize: '14px' } },
    { field: "Y", headerName: "Indexed", maxWidth: 150, cellStyle: { fontSize: '14px' } },
    { field: "N", headerName: "Not Indexed", maxWidth: 150, cellStyle: { fontSize: '14px' } }
  ];

  const startTimer = () => {
    if (timerInterval) clearInterval(timerInterval);
    setTimer(0);
    const interval = setInterval(() => setTimer(prev => prev + 1), 1000);
    setTimerInterval(interval);
  };

  const handleCheck = async () => {
    setLoading(true);
    startTimer();
    try {
      const response = await fetch("/api/search/index-check", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ country, keywords, asins })
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setResults(data);
        const nonIndexedKeywords = data.reduce((acc, { keyword, indexed }) => {
          if (indexed === 'N') acc[keyword] = 'not indexed';
          else if (!acc[keyword]) acc[keyword] = 'indexed';
          return acc;
        }, {});

        const wordsStatus = {};
        Object.entries(nonIndexedKeywords).forEach(([keyword, status]) => {
          keyword.toLowerCase().split(' ').forEach(word => {
            word = word.trim();
            if (word && word.length > 2) {
              if (!wordsStatus[word]) wordsStatus[word] = status;
              else if (wordsStatus[word] !== status) wordsStatus[word] = 'mixed';
            }
          });
        });

        const filteredWords = Object.entries(wordsStatus).filter(([, status]) => status === 'not indexed').map(([word]) => word).sort();
        setNonIndexedWords(filteredWords);
      } else {
        console.error("Received non-array data:", data);
        setResults([]);
        if (data.error) console.error("Error:", data.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setResults([]);
    } finally {
      setLoading(false);
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const handleExportCSV = useCallback(() => {
    if (viewMode === 'pivot') keywordPivotGridRef.current?.api.exportDataAsCsv();
    else if (viewMode === 'asin_pivot') asinPivotGridRef.current?.api.exportDataAsCsv();
    else detailGridRef.current?.api.exportDataAsCsv();
  }, [viewMode]);

  useEffect(() => {
    const keywordCount = keywords.split('\n').filter(k => k.trim()).length;
    const asinCount = asins.split('\n').filter(a => a.trim()).length;
    const totalCalculations = keywordCount * asinCount;
    setCalculations({ total: totalCalculations, estimatedTime: totalCalculations * 0.55 });
  }, [keywords, asins]);

  useEffect(() => { return () => { if (timerInterval) clearInterval(timerInterval); }; }, [timerInterval]);

  const calculatePivotData = (results) => {
    const pivot = {};
    results.forEach(row => {
      if (!pivot[row.keyword]) pivot[row.keyword] = { keyword: row.keyword, Y: 0, N: 0, total: 0 };
      pivot[row.keyword][row.indexed]++;
      pivot[row.keyword].total++;
    });
    return Object.values(pivot).sort((a, b) => b.N !== a.N ? b.N - a.N : a.keyword.localeCompare(b.keyword));
  };

  const calculateAsinPivotData = (results) => {
    const pivot = {};
    results.forEach(row => {
      if (!pivot[row.asin]) pivot[row.asin] = { asin: row.asin, Y: 0, N: 0, total: 0 };
      pivot[row.asin][row.indexed]++;
      pivot[row.asin].total++;
    });
    return Object.values(pivot).sort((a, b) => b.N !== a.N ? b.N - a.N : a.asin.localeCompare(b.asin));
  };

  const keywordCount = keywords.split('\n').filter(k => k.trim()).length;
  const asinCount = asins.split('\n').filter(a => a.trim()).length;

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Index Checker</Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>Export CSV</Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} height="calc(100vh - 80px)">
        <Box display="flex" flexDirection="column" gap={1} width="250px">
          <TextField select label="Country" value={country} onChange={(e) => setCountry(e.target.value)} sx={{ width: "250px" }}>
            {countries.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
          </TextField>

          <Box position="relative" width="250px">
            <TextField label="ASINs (one per line)" multiline rows={8} value={asins} onChange={(e) => setAsins(e.target.value)} sx={{ width: "100%" }} />
            <Box position="absolute" bottom={8} right={8} bgcolor="grey.300" color="black" borderRadius="50%" width={24} height={24} display="flex" alignItems="center" justifyContent="center" fontSize="0.75rem">{asinCount}</Box>
          </Box>

          <Box position="relative" width="250px">
            <TextField label="Keywords (one per line)" multiline rows={15} value={keywords} onChange={(e) => setKeywords(e.target.value)} sx={{ width: "100%" }} />
            <Box position="absolute" bottom={8} right={8} bgcolor="grey.300" color="black" borderRadius="50%" width={24} height={24} display="flex" alignItems="center" justifyContent="center" fontSize="0.75rem">{keywordCount}</Box>
          </Box>

          <Button variant="contained" onClick={handleCheck} disabled={loading} sx={{ width: "250px" }}>{loading ? "Checking..." : "Check"}</Button>

          {calculations.total > 0 && (
            <Box display="flex" flexDirection="column" gap={1} mt={2}>
              <Typography variant="body2" sx={{ textAlign: 'left', color: 'text.primary', whiteSpace: 'nowrap' }}>
                Total Checks: <span style={{ color: calculations.total > 500 ? 'red' : 'inherit' }}>{calculations.total}</span>
                {calculations.total > 500 && (
                  <span title="Running these many checks may take a lot of time, and has the chance of failing. It is better to break it up into 2 separate checks:
1. All ASINs of a line with approx 10-15 keywords - will highlight any ASINs that are not indexing at all
2. 5 ASINs of a line with all keywords - will highlight all keywords that are not indexing at all" style={{ color: 'red', cursor: 'help', marginLeft: '4px' }}>⚠️</span>
                )}<br />
                Total Time: ~{Math.floor(calculations.estimatedTime / 60)} mins {Math.floor(calculations.estimatedTime % 60)} secs
              </Typography>
              {timer > 0 && (
                <Typography variant="body2" sx={{ textAlign: 'left', color: 'text.primary', whiteSpace: 'nowrap' }}>
                  Elapsed Time: {Math.floor(timer / 60)}:{(timer % 60).toString().padStart(2, '0')}
                  {loading && (<><br /><span style={{ color: 'brown' }}>Do not navigate away while this is running</span></>)}
                </Typography>
              )}
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" flex={1}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs value={viewMode} onChange={(e, newValue) => setViewMode(newValue)} aria-label="view mode tabs">
              <Tab label="Summary by Keyword" value="pivot" />
              <Tab label="Summary by ASIN" value="asin_pivot" />
              <Tab label="Detailed View" value="detail" />
              <Tab label="Overall Summary" value="summary" />
            </Tabs>
          </Box>

          <div className="ag-theme-alpine" style={{ flex: 1 }}>
            {viewMode === 'pivot' ? (
              <AgGridReact ref={keywordPivotGridRef} rowData={calculatePivotData(results)} columnDefs={pivotColumns} {...AGGrid_Options} sideBar={AGGrid_Sidebar} />
            ) : viewMode === 'asin_pivot' ? (
              <AgGridReact ref={asinPivotGridRef} rowData={calculateAsinPivotData(results)} columnDefs={asinPivotColumns} {...AGGrid_Options} sideBar={AGGrid_Sidebar} />
            ) : viewMode === 'detail' ? (
              <AgGridReact ref={detailGridRef} rowData={results} columnDefs={columns} {...AGGrid_Options} sideBar={AGGrid_Sidebar} />
            ) : (
              <OverallSummary nonIndexedWords={nonIndexedWords} />
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default IndexChecker;