import React, { useCallback, useEffect, useState } from "react";
import { useGridFilter } from "ag-grid-react";

const ImpactFilter = ({ model, onModelChange, colDef, api, column }) => {
  const [unappliedModel, setUnappliedModel] = useState(model);

  const doesFilterPass = useCallback((params) => {
    if (params && params.data) {
      const impactField = colDef.field;
      const impactValue = parseFloat(params.data[impactField]);
      
      if (impactValue === null || impactValue === undefined || isNaN(impactValue)) {
        return false;
      }

      if (unappliedModel === 'bigImpact') {
        return impactValue >= 0.005 || impactValue <= -0.005;
      }
      return true;
    }
    return false;
  }, [unappliedModel, colDef]);

  const afterGuiAttached = useCallback(() => {
    // No need for closeFilter functionality
  }, []);

  useGridFilter({
    doesFilterPass,
    afterGuiAttached,
  });

  useEffect(() => {
    setUnappliedModel(model);
  }, [model]);

  const onImpactChange = ({ target: { value } }) => {
    const newModel = value === "All" ? null : value;
    setUnappliedModel(newModel);
    onModelChange(newModel);

    if (value === "bigImpact" && api) {
      // Sort the column in ascending order
      api.applyColumnState({
        state: [
          {
            colId: colDef.field,
            sort: 'desc'
          }
        ],
        defaultState: { sort: null }
      });
    }
  };

  return (
    <div className="impact-filter">
      <label>
        <input
          type="radio"
          name="impact"
          value="All"
          checked={unappliedModel == null}
          onChange={onImpactChange}
        />{" "}
        All
      </label>
      <br />
      <label>
        <input
          type="radio"
          name="impact"
          value="bigImpact"
          checked={unappliedModel === 'bigImpact'}
          onChange={onImpactChange}
        />{" "}
        Big Impact
      </label>
    </div>
  );
};

export default ImpactFilter;