import React from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const SummaryTable = ({ data }) => {
  const formatNumber = (num) => {
    if (num === null || num === undefined) return '-';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num);
  };

  const formatPercentage = (num) => {
    if (num === null || num === undefined) return '-';
    return `${(num * 100).toFixed(1)}%`;
  };

  const tableStyles = {
    container: {
      maxWidth: '600px',
    },
    headerCell: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
    metricCell: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
    },
    dataCell: {
      fontSize: '1.1rem',
    }
  };

  return (
    <Box mb={4}>
      <Typography variant="h5" mb={2} sx={{ fontWeight: 'bold' }}>Performance Summary</Typography>
      <TableContainer component={Paper} sx={tableStyles.container}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={tableStyles.headerCell}>Metric</TableCell>
              <TableCell align="right" sx={tableStyles.headerCell}>{new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { month: 'short' })}</TableCell>
              <TableCell align="right" sx={tableStyles.headerCell}>MoM Δ</TableCell>
              <TableCell align="right" sx={tableStyles.headerCell}>YoY Δ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={tableStyles.metricCell}>Units Sold</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatNumber(data.units)}</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatPercentage(data.unitsMoM)}</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatPercentage(data.unitsYoY)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableStyles.metricCell}>Sales</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatNumber(data.sales)}</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatPercentage(data.salesMoM)}</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatPercentage(data.salesYoY)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={tableStyles.metricCell}>Net Profit</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatNumber(data.profit)}</TableCell>
              <TableCell align="right" sx={tableStyles.dataCell}>{formatPercentage(data.profitMoM)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SummaryTable; 