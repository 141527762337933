import React, { useState, useRef, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, Button, Typography } from '@mui/material';
import QuickFilter from '../../components/global/QuickFilter';

const Pricing_Fee_Preview = () => {
  const [rowData, setRowData] = useState([]);
  const [error, setError] = useState(null);
  const [appliedQuickFilter, setAppliedQuickFilter] = useState('');
  const gridRef = useRef(null);

  const fetchPricingFeePreviewData = useCallback(() => {
    console.log('Initiating Pricing Fee Preview data fetch');
    fetch('/api/fulfilment_fee/get_pricing_fee_preview')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); })
      .then(text => {
        // console.log('Raw response:', text);
        try {
          const data = JSON.parse(text);
          // console.log('Parsed data:', data);
          return data;
        } catch (error) {
          console.error('Error parsing JSON:', error);
          throw new Error('Invalid JSON response');
        }
      })
      .then(data => {
        if (Array.isArray(data)) {
          console.log(`Number of rows received: ${data.length}`);
          setRowData(data);
        } else if (typeof data === 'object' && data !== null) {
          // console.log('Received object:', data);
          if (data.message === "Cache cleared successfully") {
            return new Promise(resolve => setTimeout(resolve, 2000))
              .then(() => fetchPricingFeePreviewData());
          } else {
            throw new Error('Received data is not an array or cache clear message');
          }
        } else {
          throw new Error('Received data is not an array or object');
        }
      })
      .catch(error => {
        console.error('Error fetching Pricing Fee Preview data:', error);
        setError(error.message);
        setRowData([]);
      });
  }, []);

  useEffect(() => {
    document.title = "Pricing Fee Preview | Encasa HQ";
    fetchPricingFeePreviewData();
  }, [fetchPricingFeePreviewData]);
  
  const columns = [
    {
      headerName: 'Basic Info',
      children: [
        {  headerName: "Country",  field: "country_code", sortable: true,  filter: true,  width: 120,  pinned: 'left', enableRowGroup: true  },
        {  headerName: "Line",  field: "line", sortable: true,  filter: true,  width: 120,  pinned: 'left', enableRowGroup: true  },
        {  headerName: "Combo",  field: "combo",  sortable: true,  filter: true,  width: 180, enableRowGroup: true, columnGroupShow: 'open'},        
        {  headerName: "Size",  field: "size",  sortable: true,  filter: true,  width: 130, enableRowGroup: true  },
        {  headerName: "Pcs Pack",  field: "pcs_pack",  sortable: true,  filter: true, enableRowGroup: true,  width: 100, columnGroupShow: 'open'},
        {  headerName: "Production",  field: "production",  sortable: true,  filter: true,  width: 120, enableRowGroup: true },        
      ]
    },
    {
      headerName: 'Pricing Details',
      children: [
        { headerName: "Required Price",  field: "reqd_price",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "COGS",  field: "calc_cogs",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,columnGroupShow: 'open',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "GST",  field: "gst1",  sortable: true,  filter: true,  width: 100,columnGroupShow: 'open',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "Referral Fee",  field: "referral_fee_mode",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "Fulfillment Fee",  field: "fulfillment_fee_mode",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "GM",  field: "GM1",  sortable: true,  filter: 'agNumberColumnFilter',  width: 100,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "Expected Fulfilment Fees", field: "expected_fulfilment_fees",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } }
      ]
    },
    {
      headerName: 'SB Report (Last Month)',
      children: [
        { headerName: "COGS %",  field: "cogs_perc",  sortable: true,  filter: 'agNumberColumnFilter',  width: 120,columnGroupShow: 'open',
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "FBA Commision %",  field: "fba_commission_perc",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "FBA Fulfillmentfee %", field: "fba_fulfillmentfee_perc",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "FBA Storagefees %", field: "fba_storagefees_perc",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },
        { headerName: "TACoS %", field: "tacos_sb",  sortable: true,  filter: 'agNumberColumnFilter',  width: 130,
          valueFormatter: params => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 2}) : '', cellStyle: { textAlign: 'right' } },  
      ]
    }
  ];

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const handleClearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
    setAppliedQuickFilter('');
  }, []);

  const handleQuickFilterChange = useCallback((filter) => {
    setAppliedQuickFilter(filter);
  }, []);

  const sideBar = {
    toolPanels: [
      { id: 'columns', labelDefault: 'Columns', labelKey: 'columns', iconKey: 'columns', toolPanel: 'agColumnsToolPanel', },
      { id: 'filters', labelDefault: 'Filters', labelKey: 'filters', iconKey: 'filter', toolPanel: 'agFiltersToolPanel', },
    ],
  };

  const handlePricingFeeClearCache = useCallback(() => {
    fetch('/api/fulfilment_fee/clear_pricing_fee_cache', { method: 'POST' })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Cache cleared:', data);
        // Wait for a short time before refetching data
        setTimeout(() => {
          fetchPricingFeePreviewData();
        }, 2000);
      })
      .catch(error => {
        console.error('Error clearing cache:', error);
        setError('Failed to clear cache. Please try again.');
      });
  }, [fetchPricingFeePreviewData]);

  return (
    <div style={{ padding: '10px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4">Pricing Fee Preview</Typography>
        <Box display="flex" alignItems="center">
          <QuickFilter onFilterChange={handleQuickFilterChange} />
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleClearFilters} 
            style={{ marginRight: '10px' }}
          >
            Clear Filters
          </Button>
          <Button 
                variant="contained" 
                color="warning" 
                onClick={handlePricingFeeClearCache} 
                style={{ marginRight: '10px' }}
              >
                Clear Cache
              </Button>
          <Button variant="contained" color="primary" onClick={handleExportCSV}>
            Export CSV
          </Button>
        </Box>
      </Box>
      {error ? (
        <Typography color="error" variant="h6">
          Error: {error}
        </Typography>
      ) : (
        <div className="ag-theme-alpine" style={{ flex: 1, width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columns}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            enableRangeSelection={true}
            multiSortKey="ctrl"
            sideBar={sideBar}
            pagination={true}
            paginationPageSize={50}
            paginationPageSizeSelector={[50, 100, 500, 1000]}
            rowHeight={25}
            quickFilterText={appliedQuickFilter}
            cacheQuickFilter={true}
          />
        </div>
      )}
    </div>
  );
};

export default Pricing_Fee_Preview;