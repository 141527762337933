import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, TextField, Button, Paper, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const KeywordExplorer = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [query, setQuery] = useState('');
  const [numKeywords, setNumKeywords] = useState(30);
  const [similarKeywords, setSimilarKeywords] = useState([]);
  const [newKeywords, setNewKeywords] = useState('');
  const [feedback, setFeedback] = useState('');
  const gridRef = useRef(null);
  const [country, setCountry] = useState('US');
  const [searchType, setSearchType] = useState('keyword');

  useEffect(() => {
    document.title = "Keyword Explorer | Encasa HQ";
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleGetSimilarKeywords = async () => {
    try {
      const response = await fetch(`/api/typesense/keywords/search?q=${encodeURIComponent(query)}&country=${country}&per_page=${numKeywords}&search_type=${searchType}`);
      const data = await response.json();
      if (data.status === 'success') {
        setSimilarKeywords(data.keywords);
      } else {
        setFeedback('Error: ' + data.message);
      }
    } catch (error) {
      console.error('Error searching keywords:', error);
      setFeedback('Error searching keywords');
    }
  };

  const handleAddKeywords = async () => {
    try {
      const keywords = newKeywords.split('\n').map(kw => kw.trim()).filter(kw => kw);
      const response = await fetch('/api/typesense/keywords/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ keywords, country }),
      });
      const data = await response.json();
      setFeedback(data.message);
      setNewKeywords('');
    } catch (error) {
      console.error('Error adding keywords:', error);
      setFeedback('Error adding keywords');
    }
  };

  const handleExportCSV = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);

  const columns = [
    { headerName: 'Keyword', field: 'keyword', sortable: true, filter: true, flex: 2 },
    { 
      headerName: 'Search Volume', 
      field: 'searchVolume', 
      sortable: true, 
      filter: true,
      flex: 1,
      valueFormatter: (params) => params.value?.toLocaleString() || '0',
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Rank',
      field: 'rank',
      sortable: true,
      filter: true,
      flex: 1,
      valueFormatter: (params) => params.value?.toLocaleString() || '0',
      cellStyle: { textAlign: 'right' }
    },
    {
      headerName: 'Relevance Score',
      field: 'score',
      sortable: true,
      filter: true,
      flex: 1,
      valueFormatter: (params) => params.value?.toFixed(4) || '0',
      cellStyle: { textAlign: 'right' }
    }
  ];

  return (
    <Box m="20px">
      <Typography variant="h4" fontWeight="bold" mb="20px">
        Keyword Explorer
      </Typography>

      <Paper elevation={3} sx={{ mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tab label="Get Similar Keywords" />
          <Tab label="Add Keywords" />
        </Tabs>
      </Paper>

      {activeTab === 0 && (
        <Box>
          <Box display="flex" flexDirection="column" mb={3}>
            <TextField
              label="Search Keywords"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ mb: 2 }}
              fullWidth
              variant="outlined"
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <TextField
                type="number"
                label="Number of keywords"
                value={numKeywords}
                onChange={(e) => setNumKeywords(parseInt(e.target.value, 10) || '')}
                sx={{ width: '200px' }}
                variant="outlined"
              />
              <FormControl sx={{ width: '200px' }}>
                <InputLabel>Country</InputLabel>
                <Select
                  value={country}
                  label="Country"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <MenuItem value="US">United States</MenuItem>
                  <MenuItem value="UK">United Kingdom</MenuItem>
                  <MenuItem value="CA">Canada</MenuItem>
                  <MenuItem value="AU">Australia</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: '200px' }}>
                <InputLabel>Search Type</InputLabel>
                <Select
                  value={searchType}
                  label="Search Type"
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <MenuItem value="keyword">Keyword Match</MenuItem>
                  <MenuItem value="semantic">Semantic Search</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <Button variant="contained" onClick={handleGetSimilarKeywords} sx={{ mr: 2 }}>
                  Search Keywords
                </Button>
                <Button variant="outlined" onClick={handleExportCSV}>
                  Export CSV
                </Button>
              </Box>
            </Box>
          </Box>
          <Box height="600px" width="100%" className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              columnDefs={columns}
              rowData={similarKeywords}
              pagination={true}
              paginationPageSize={100}
              defaultColDef={{
                sortable: true,
                filter: true,
                resizable: true,
              }}
              enableRangeSelection={true}
              copyHeadersToClipboard={true}
              suppressCopyRowsToClipboard={false}
            />
          </Box>
        </Box>
      )}

      {activeTab === 1 && (
        <Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Country</InputLabel>
            <Select
              value={country}
              label="Country"
              onChange={(e) => setCountry(e.target.value)}
            >
              <MenuItem value="US">United States</MenuItem>
              <MenuItem value="UK">United Kingdom</MenuItem>
              <MenuItem value="CA">Canada</MenuItem>
              <MenuItem value="AU">Australia</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            rows={20}
            label="Enter keywords (one per line)"
            value={newKeywords}
            onChange={(e) => setNewKeywords(e.target.value)}
            sx={{ mb: 2 }}
            variant="outlined"
          />
          <Button variant="contained" onClick={handleAddKeywords}>
            Add Keywords
          </Button>
          {feedback && (
            <Typography sx={{ mt: 2 }} color="primary">
              {feedback}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default KeywordExplorer;