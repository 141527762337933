import React, { useState, useCallback } from 'react';
import { TextField } from "@mui/material";

const QuickFilter = ({ onFilterChange }) => {
  const [quickFilter, setQuickFilter] = useState('');

  const handleQuickFilterChange = useCallback((event) => {
    setQuickFilter(event.target.value);
  }, []);

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      onFilterChange(quickFilter);
    }
  }, [quickFilter, onFilterChange]);

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Quick Filter... (Press Enter to apply)"
      value={quickFilter}
      onChange={handleQuickFilterChange}
      onKeyPress={handleKeyPress}
      style={{ marginRight: '10px', width: '250px' }}
    />
  );
};

export default QuickFilter;