import React from 'react';
import { getRatioValue } from '../../components/global/getRatioValue';
import AlertPillsCellRenderer from '../../components/global/AlertPillsCellRenderer.jsx';
import { columns as lineDetailsColumns } from '../line_details/lineDetailsColumns';
import AGGrid_Options from '../../components/global/AGGrid_Options';

/**
 * Helper function to create filtered column sets
 * 
 * This function takes a list of column fields, a sort field, and a sort order as inputs.
 * It filters the columns based on the provided list of column fields and applies the specified sorting.
 * 
 * @param {Array} columns - List of column fields to include in the column set.
 * @param {string} sortField - The field to sort by.
 * @param {string} sortOrder - The order to sort by ('asc' for ascending, 'desc' for descending).
 * @returns {Array} - A filtered and sorted column set.
 */
const createColumnSet = (columns, sortField, sortOrder) => {
  return lineDetailsColumns.map(group => {
    if (group.children) {
      const filteredChildren = group.children.filter(col => 
        columns.includes(col.field)
      ).map(col => {
        if (col.field === sortField) {
          return { ...col, sort: sortOrder, sortIndex: 0 };
        }
        return col;
      });
      if (filteredChildren.length > 0) {
        return { ...group, children: filteredChildren };
      }
    }
    return null;
  }).filter(group => group !== null);
};

// Define column sets for different views
const commonColumns = [
  "country_code", "line", "dri", "group", "segment", "ma", 
  "_l1", "mom_delta", "_l2", "_l3", "yoy_delta", "_l13",   
  "sales_sb", "net_profit_sb", "return_perc", "profit_perc", "cogs_perc", 
  "fba_commission_perc", "fba_fulfillmentfee_perc", "fba_storagefees_perc",
];

// Use common columns for all views
const biggestLinesColumns = commonColumns;
const topGrowthColumns = commonColumns;
const topDegrowthColumns = commonColumns;
const topProfitColumns = commonColumns;
const topLossColumns = commonColumns;

// Add grouped columns configuration
const lineSummaryColumns = [
  { headerName: 'Line', field: 'line', rowGroup: true, hide: true, width: 110 },
  { headerName: 'Country', field: 'country_code', width: 120, rowGroup: true },
  { headerName: 'MA', field: 'ma', aggFunc: 'sum', width: 100, cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value ? params.value.toLocaleString('en-US', {maximumFractionDigits: 0}) : '-' },
  { headerName: 'YoY Δ', field: 'yoy_delta', aggFunc: 'avg', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.data && params.data._l1 && params.data._l13 && params.data._l13 !== 0 ? `${((params.data._l1 / params.data._l13) * 100).toFixed(1)}%` : '-' },
  { headerName: 'MoM Δ', field: 'mom_delta', aggFunc: 'avg', width: 120, cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.data && params.data._l2 && params.data._l1 && params.data._l1 !== 0 ? `${((params.data._l2 / params.data._l1) * 100).toFixed(1)}%` : '-' },
  { headerName: 'L1', field: '_l1', width: 90, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'L2', field: '_l2', width: 90, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'L3', field: '_l3', width: 90, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'L13', field: '_l13', width: 90, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'Sales', field: 'sales_sb', width: 110, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'Net Profit', field: 'net_profit_sb', width: 110, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'Net Profit L1', field: 'net_profit_l1', width: 110, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'Net Profit L2', field: 'net_profit_l2', width: 110, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' },
  { headerName: 'Net Profit L3', field: 'net_profit_l3', width: 110, aggFunc: 'sum', cellStyle: { textAlign: 'right' }, valueFormatter: (params) => params.value?.toLocaleString() ?? '-' }
];

// Add country summary columns (same as line summary but with different grouping)
const countrySummaryColumns = [
  { headerName: 'Country', field: 'country_code', rowGroup: true, hide: true, width: 120 },
  { headerName: 'Line', field: 'line', width: 110 },
  ...lineSummaryColumns.slice(2) // Reuse the rest of the columns from lineSummaryColumns
];

// Create filtered column sets
export const columnSets = {
  biggestLines: createColumnSet(biggestLinesColumns, 'sales_sb', 'desc'),
  topGrowth: createColumnSet(topGrowthColumns, 'mom_delta', 'desc'),
  topDegrowth: createColumnSet(topDegrowthColumns, 'mom_delta', 'asc'),
  topProfit: createColumnSet(topProfitColumns, 'net_profit_sb', 'desc'),
  topLoss: createColumnSet(topLossColumns, 'net_profit_sb', 'asc'),
  lineSummary: lineSummaryColumns,
  countrySummary: countrySummaryColumns
};

export const gridOptions = {
  biggestLines: { ...AGGrid_Options, pagination: false },
  topGrowth: { ...AGGrid_Options, pagination: false },
  topDegrowth: { ...AGGrid_Options, pagination: false },
  topProfit: { ...AGGrid_Options, pagination: false },
  topLoss: { ...AGGrid_Options, pagination: false },
  lineSummary: {
    ...AGGrid_Options,
    pagination: false,
    groupDefaultExpanded: 0,
    autoGroupColumnDef: {
      headerName: 'Line',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true
      }
    },
    defaultColDef: {
      ...AGGrid_Options.defaultColDef,
      cellStyle: { textAlign: 'right' }
    },
    sortModel: [
      { colId: 'ma', sort: 'desc' }
    ]
  },
  countrySummary: {
    ...AGGrid_Options,
    pagination: false,
    groupDefaultExpanded: 0,
    autoGroupColumnDef: {
      headerName: 'Country',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true
      }
    },
    defaultColDef: {
      ...AGGrid_Options.defaultColDef,
      cellStyle: { textAlign: 'right' }
    },
    sortModel: [
      { colId: 'sales_sb', sort: 'desc' }
    ]
  }
};